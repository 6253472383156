import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import ListItem from "@mui/material/ListItem";

import { getAuthToken } from "../../../internal/authToken";
import * as snackbarActions from "../../../store/actions/snackbarActions";
import { initAppAction } from "../../../store/actions/builderAction";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer, builder: state.builderReducer };
};

function Dashboard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [auth, setAuth] = React.useState(null);
  const [appsList, setAppsList] = React.useState([]);
  const [fetchingList, setFetchingList] = React.useState(true);

  function goToBuilder() {
    navigate("/dashboard/create/");
    // dispatch(initAppAction({}));
    // console.log(props.builder.app);
  }

  useEffect(() => {
    setAuth(getAuthToken());
    dispatch(initAppAction({}));

    function goToApp(appid) {
      if (appid) {
        dispatch(initAppAction({}));
        navigate("/dashboard/builder/" + appid + "/");
      }
    }

    function getUserApps() {
      const rawData = { token: getAuthToken().token };
      setFetchingList(true);
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: rawData,
        url: config.url.REPLY_SERVER + "/api/v1/apps/list",
      })
        .then(function (res) {
          setFetchingList(false);
          if (res.data && res.data.response) {
            if (res.data.response.status === 1) {
              let temp = [];
              res.data.response.appsList.map(function (item, key) {
                if (item) {
                  temp.push(
                    <Box key={key}>
                      <ListItem button onClick={() => goToApp(item.appId)}>
                        @{item.appUsername}
                      </ListItem>
                    </Box>
                  );
                }

                return item;
              });
              setAppsList(temp);
            } else if (
              res.data.response.status === 0 &&
              res.data.response.errors != null
            ) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          setFetchingList(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Error fetching Apps(130)",
              severity: "error",
              autoHide: true,
            })
          );
        });
    }

    if (getAuthToken() && getAuthToken().token) {
      getUserApps();
    }
  }, [dispatch, navigate]);

  return (
    <Grid
      item
      xs={12}
      sx={{ backgroundColor: "background.default", minWidth: "100%" }}>
      <Box
        sx={{
          marginTop: "30px",
          minWidth: "50%",
          display: "inline-block",
        }}>
        {auth && auth.token ? (
          <Stack spacing={3}>
            <Box justifyContent='left' alignItems='left'>
              <Box sx={{ fontSize: "40px" }}>Bots</Box>
              <Box>List of Bots you created</Box>
              <br />
              {fetchingList === true ? (
                <Box>
                  <Skeleton />
                  <Skeleton animation={false} />
                  <Skeleton animation='wave' />
                </Box>
              ) : appsList.length > 0 ? (
                appsList
              ) : (
                <Box>You dont have any Apps now</Box>
              )}
            </Box>
            <Divider />
            <Button
              variant='outlined'
              fullwidth='true'
              onClick={() => goToBuilder()}
              sx={{
                minWidth: "20%",
              }}>
              Create New Bot
            </Button>
          </Stack>
        ) : (
          <Box>Please Login</Box>
        )}
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(Dashboard);
