import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import Chart from "../Chart";
import EditDialog from "../EditDialog/EditDialog";
import {
  setCurrentBlock,
  editNodeAction,
  setCurrentWidget,
} from "../../../store/actions/builderAction";
import { getAuthToken } from "../../../internal/authToken";

const MapStateToProps = (state) => {
  return {
    message: state.newMessageReducer,
    errors: state.errorReducer,
    builder: state.builderReducer,
  };
};

function Canvas(props) {
  const dispatch = useDispatch();
  const [auth, setAuth] = React.useState(null);

  useEffect(() => {
    setAuth(getAuthToken());
  }, []);

  function closeEditItemPanel() {
    dispatch(
      editNodeAction({
        openDialog: "none",
        addNew: null,
        parentId: null,
      })
    );

    dispatch(setCurrentWidget(""));
  }

  function backToMainBlock() {
    dispatch(setCurrentBlock(null));
    closeEditItemPanel();
  }

  return (
    <Grid
      container
      justify='center'
      spacing={0}
      direction='column'
      alignItems='center'
      sx={{ backgroundColor: "background.default" }}
      justifyContent='center'>
      <Grid item justify='center' xs={12} sx={{ textAlign: "start" }}>
        {props.builder.getCurrentBlock ? (
          <Button
            variant='outlined'
            onClick={() => backToMainBlock()}
            sx={{ marginLeft: "20px", marginTop: "20px" }}>
            Back To Main Block
          </Button>
        ) : (
          ""
        )}

        <Stack spacing={3} direction='row'>
          <Container
            maxWidth={false}
            sx={{
              overflow: "hidden",
              padding: "0px !important",
            }}>
            <TransformWrapper
              initialScale={1}
              limitToBounds={false}
              centerZoomedOut={false}
              minScale={0.1}
              maxScale={3}
              centerOnInit={true}
              zoomAnimation={{ disabled: true }}
              panning={{ velocityDisabled: true }}>
              <TransformComponent>
                {auth && auth.token ? (
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    justify='center'
                    sx={{
                      marginTop: "-50px",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "background.default",
                    }}>
                    <Chart />
                  </Box>
                ) : (
                  <Box sx={{ backgroundColor: "background.default" }}>
                    Please Login
                  </Box>
                )}
              </TransformComponent>
            </TransformWrapper>
          </Container>
          <Box sx={{ backgroundColor: "background.default" }}>
            <EditDialog edit={props.builder.editNode} sx={{ marginTop: 0 }} />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default connect(MapStateToProps)(Canvas);
