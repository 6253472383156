import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  addNewItemAction,
  setCurrentWidget,
  editNodeAction,
  editItemAction,
  deleteItemAction,
  setBuilderDialogIsBusy,
} from "../../../../store/actions/builderAction";

import { getAuthToken } from "../../../../internal/authToken";
import * as snackbarActions from "../../../../store/actions/snackbarActions";
import { config } from "../../../../config/Constants";
import { GetItemInfo } from "../../../../internal/buildApp";

const MapStateToProps = (state) => {
  return { builder: state.builderReducer, system: state.systemReducer };
};

var ifWidget;
var item;

function SelectSay(props) {
  const dispatch = useDispatch();
  const [ifCondition, setIfCondition] = React.useState("=");
  const [ifLabel, setIfLabel] = React.useState("");
  const [ifLabelError, setIfLabelError] = React.useState(false);
  const [ifConditionValue, setIfConditionValue] = React.useState("");
  const [ifConditionValueError, setIfConditionValueError] =
    React.useState(false);
  const [ifSuccessMessage, setIfSuccessMessage] = React.useState("");
  const [ifSuccessMessageError, setIfSuccessMessageError] =
    React.useState(false);
  const [ifFailMessage, setIfFailMessage] = React.useState("");
  const [ifFailMessageError, setIfFailMessageError] = React.useState(false);

  const [newLoaded, setNewLoaded] = React.useState(false);
  const [isNewItem, setisNewItem] = React.useState(true);

  useEffect(() => {
    //check if we load data for the first time or not. if we dont check, whole process renders every time
    if (!newLoaded) {
      setNewLoaded(true);
      //ٌwhen we want to edit an item, not adding new one
      if (props.edit && props.edit.editItem && props.edit.targetWidget) {
        setisNewItem(false);
        item = GetItemInfo(props.builder.app, props.edit.itemId);
        if (item) {
          ifWidget = item;

          if (item.condition) {
            setIfCondition(item.condition);
          }

          if (item.conditionValue) {
            setIfConditionValue(item.conditionValue);
          }

          if (item.label) {
            setIfLabel(item.label);
          }

          if (item.successMessage) {
            setIfSuccessMessage(item.successMessage);
          }

          if (item.failMessage) {
            setIfFailMessage(item.failMessage);
          }
        }
      }
    }

    if (isNewItem && props.edit && !props.edit.editItem) {
      //if we want to create new say node
      ifWidget = {
        id: uuidv4(),
        type: "if",
        parentBlock: "",
        condition: ifCondition,
        conditionValue: ifConditionValue,
        label: ifLabel,
        successMessage: ifSuccessMessage,
        failMessage: ifFailMessage,
        ifTrue: "",
        ifFalse: "",
        next: "",
        parent: "",
      };
    }
  }, [
    ifCondition,
    ifConditionValue,
    ifLabel,
    ifSuccessMessage,
    ifFailMessage,
    props.edit,
    props.builder.app,
    newLoaded,
    isNewItem,
  ]);

  function dispatchBuilderDialogIsBusyAction(value) {
    dispatch(setBuilderDialogIsBusy(value));
  }

  function clearWidgetSelectStore() {
    dispatch(setCurrentWidget(""));
  }

  function closeEditItemPanel() {
    dispatch(
      editNodeAction({
        openDialog: "none",
        addNew: null,
        parentId: null,
      })
    );
    clearWidgetSelectStore();
  }

  function handleIfLabelChange(e) {
    let val = e.target.value;
    const res = /[`*+%$[\]{}\\|<>~]/.test(val);
    if (!res || val === "") {
      setIfLabel(val);
    }
  }

  function handleIfConditionChange(e) {
    let val = e.target.value;
    const res = /[=<>]+$/.test(val);
    if (!res || val === "") {
      setIfCondition(val);
    }
  }

  function handleIfConditionValueChange(e) {
    let val = e.target.value;
    const res = /[`*+%$[\]{}\\|<>~]/.test(val);
    if (!res || val === "") {
      setIfConditionValue(val);
    }
  }

  function handleIfSuccessMessageChange(e) {
    let val = e.target.value;
    const res = /[`*+%$[\]{}\\|<>~]/.test(val);
    if (!res || val === "") {
      setIfSuccessMessage(val);
    }
  }

  function handleIfFailMessageChange(e) {
    let val = e.target.value;
    const res = /[`*+%$[\]{}\\|<>~]/.test(val);
    if (!res || val === "") {
      setIfFailMessage(val);
    }
  }

  function addNewItem() {
    if (ifWidget) {
      ifWidget.condition = ifCondition;
      ifWidget.label = ifLabel;
      ifWidget.conditionValue = ifConditionValue;
      ifWidget.successMessage = ifSuccessMessage;
      ifWidget.failMessage = ifFailMessage;
    }
    if (
      ifWidget &&
      ifWidget.id &&
      ifWidget.type === "if" &&
      ifWidget.condition &&
      ifWidget.condition.length > 0 &&
      ifWidget.label &&
      ifWidget.label.length > 0 &&
      ifWidget.conditionValue &&
      ifWidget.conditionValue.length > 0 &&
      ifWidget.successMessage &&
      ifWidget.successMessage.length > 0 &&
      ifWidget.failMessage &&
      ifWidget.failMessage.length > 0 &&
      props.edit
    ) {
      dispatchBuilderDialogIsBusyAction(true);
      if (isNewItem) {
        // if we want to add new item
        dispatch(
          addNewItemAction({
            item: ifWidget,
            config: props.edit,
            clickOnPlus: props.clickOnPlus,
            isSubWidget: props.isSubWidget,
            subType: props.subType,
            subParentType: props.subParentType,
            isAfterComplexWidget: props.isAfterComplexWidget,
            complexWidgetType: props.complexWidgetType,
            complexWidgetNext: props.complexWidgetNext,
            parentNext: props.parentNext,
            parentBlock: props.parentBlock,
          })
        );
      } else {
        //if we want to edit an item
        dispatch(
          editItemAction({
            item: ifWidget,
          })
        );
      }
      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    } else {
      if (
        (ifWidget && !ifWidget.conditionValue) ||
        ifWidget.conditionValue.length === 0
      ) {
        setIfConditionValueError(true);
      }

      if ((ifWidget && !ifWidget.label) || ifWidget.label.length === 0) {
        setIfLabelError(true);
      }

      if (
        (ifWidget && !ifWidget.successMessage) ||
        ifWidget.successMessage.length === 0
      ) {
        setIfSuccessMessageError(true);
      }

      if (
        (ifWidget && !ifWidget.failMessage) ||
        ifWidget.failMessage.length === 0
      ) {
        setIfFailMessageError(true);
      }
    }
  }

  function deleteItem() {
    if (item && item.id) {
      dispatchBuilderDialogIsBusyAction(true);
      dispatch(
        deleteItemAction({
          item: item,
        })
      );

      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    }
  }

  function saveApptoServer(app) {
    if (app && app.name && getAuthToken().token) {
      const rawData = {
        token: getAuthToken().token,
        appUsername: app.name,
        app: JSON.stringify(app),
      };
      axios({
        method: "post",
        url: config.url.REPLY_SERVER + "/api/v1/apps/saveapp",
        data: rawData,
        timeout: 15000,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (res.data && res.data.response.status === 1) {
            dispatchBuilderDialogIsBusyAction(false);
            closeEditItemPanel();
          } else {
            if (res.data.response.errors) {
              dispatchBuilderDialogIsBusyAction(false);
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          dispatchBuilderDialogIsBusyAction(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Action not saved",
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatchBuilderDialogIsBusyAction(false);
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Bad request",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Stack spacing={3}>
      <Box>
        <TextField
          id='outlined-basic'
          error={ifLabelError}
          label='Label'
          variant='outlined'
          onChange={handleIfLabelChange}
          value={ifLabel}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 30 }}
        />
      </Box>

      <Box>
        <FormControl
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}>
          <InputLabel id='select-if-condition-label'>Condition</InputLabel>
          <Select
            fullWidth
            variant='outlined'
            defaultValue={"="}
            labelId='select-if-condition-label'
            id='select-if-condition'
            value={ifCondition}
            label='Condition'
            onChange={handleIfConditionChange}
            disabled={props.builder.builderDialogIsBusy ? true : false}>
            <MenuItem value={"="}>=</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box>
        <TextField
          id='outlined-basic'
          error={ifConditionValueError}
          label='Condition Value'
          variant='outlined'
          onChange={handleIfConditionValueChange}
          value={ifConditionValue}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 50 }}
        />
      </Box>

      <Box>
        <TextField
          id='outlined-basic'
          error={ifSuccessMessageError}
          label='Success Message'
          variant='outlined'
          onChange={handleIfSuccessMessageChange}
          value={ifSuccessMessage}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 200 }}
        />
      </Box>

      <Box>
        <TextField
          id='outlined-basic'
          error={ifFailMessageError}
          label='Fail Message'
          variant='outlined'
          onChange={handleIfFailMessageChange}
          value={ifFailMessage}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 200 }}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Button
          variant='contained'
          fullWidth
          onClick={() => addNewItem()}
          disabled={
            props.builder.builderDialogIsBusy
              ? true
              : ifLabel &&
                ifCondition &&
                ifConditionValue &&
                ifSuccessMessage &&
                ifFailMessage
              ? false
              : true
          }>
          Save
        </Button>
      </Box>

      {!isNewItem ? (
        <Box sx={{ width: "100%" }}>
          <Button
            variant='contained'
            color='error'
            fullWidth
            disabled={props.builder.builderDialogIsBusy ? true : false}
            onClick={() => deleteItem()}>
            Remove
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
}
export default connect(MapStateToProps)(SelectSay);
