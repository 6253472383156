import i18n from "i18next";

var lightTheme = {
  palette: {
    mode: "light",
    background: {
      default: "#f2eee3",
      darker: "#e8e2d6",
    },
    primary: {
      light: "#525252",
      main: "#1976d2",
      dark: "#292929",
      matn: "#000000",
      title: "#000000",
      subtitle: "#404040",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      matn: "#000000",
      title: "#000000",
      subtitle: "#404040",
    },
    error: {
      light: "#ef5350",
      main: "#d32f2f",
      dark: "#c62828",
    },
    warning: {
      light: "#ff9800",
      main: "#ed6c02",
      dark: "#e65100",
    },
    info: {
      light: "#03a9f4",
      main: "#0288d1",
      dark: "#01579b",
    },
    success: {
      light: "#4caf50",
      main: "#2e7d32",
      dark: "#1b5e20",
    },
  },
  typography: {
    fontFamily: "roboto-regular",
  },
};

var darkTheme = {
  palette: {
    mode: "dark",
    background: {
      default: "#424242",
      darker: "#e8e2d6",
    },
    primary: {
      light: "#e6e6e6",
      main: "#ffffff",
      dark: "#c9c9c9",
      title: "#ffffff",
      subtitle: "#cfcfcf",
    },
    secondary: {
      light: "#e6e6e6",
      main: "#ffffff",
      dark: "#c9c9c9",
      matn: "#9c9c9c",
      title: "#ffffff",
      subtitle: "#cfcfcf",
    },
    error: {
      light: "#ef5350",
      main: "#d32f2f",
      dark: "#c62828",
    },
    warning: {
      light: "#ff9800",
      main: "#ed6c02",
      dark: "#e65100",
    },
    info: {
      light: "#03a9f4",
      main: "#0288d1",
      dark: "#01579b",
    },
    success: {
      light: "#4caf50",
      main: "#2e7d32",
      dark: "#1b5e20",
    },
  },
  typography: {
    fontFamily: "roboto-regular",
  },
};

const DynaTheme = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          height: "53px",
          borderRadius: "5px !important",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          height: "53px",
          borderRadius: "5px !important",
        },
        multiline: {
          height: "100px",
        },
      },
    },

    MuiGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "background.default !important",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        h3: {
          marginLeft: "-3px",
          marginRight: "-3px",
        },
      },
    },
  },
};

function setTheme(themeColor, themeMode, themeDir) {
  if (themeMode === "dark") {
    Object.assign(DynaTheme, darkTheme);
  } else {
    Object.assign(DynaTheme, lightTheme);
  }

  if (themeColor) {
    DynaTheme.palette.primary.main = themeColor;
  }

  if (themeDir) {
    DynaTheme.direction = themeDir;
  }

  if (i18n.language === "fa") {
    DynaTheme.typography.fontFamily = "vazir-medium";
  } else {
    DynaTheme.typography.fontFamily = "roboto-regular";
  }

  return DynaTheme;
}

export default setTheme;
