import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import i18n from "i18next";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import { loadProgressBar } from "axios-progress-bar";

import Builder from "../Builder/Builder";
import setTheme from "../../styles/theme/mainTheme";
import { wsConnect } from "../../store/actions/websocketAction";
import getHost from "../../internal/websocketConnect";
import PrivateRoute from "../../utils/PrivateRoute";
import Dashboard from "../Dashboard/Dashboard/Dashboard";
import NewApp from "../Dashboard/NewApp";
import Home from "../Home/Home";
import CustomAppBar from "../UI/CustomAppBar/CustomAppBar";
import CustomBottomNavigation from "../UI/CustomBottomNavigation/CustomBottomNavigation";
import SnackBarMessage from "../UI/Snackbar";
import Settings from "../Settings/Settings/Settings";
import Login from "../Settings/Login/Login";
import CheckOTP from "../Settings/CheckOTP/CheckOTP";
import {
  setThemeModeAction,
  setThemeDirAction,
  setThemeColorAction,
} from "../../store/actions/systemAction";
import {
  getThemeMode,
  getThemeColor,
  getThemeDir,
  getThemeLng,
} from "../../internal/saveTheme";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function App(props) {
  const dispatch = useDispatch();
  loadProgressBar({ showSpinner: false });

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  useEffect(() => {
    let host = getHost();
    dispatch(wsConnect(host));

    if (getThemeLng()) {
      i18n.changeLanguage(getThemeLng().themeLng);
    }

    if (getThemeColor()) {
      dispatch(setThemeColorAction(getThemeColor().themeColor));
    }

    if (getThemeDir()) {
      dispatch(setThemeDirAction(getThemeDir().themeDir));
    }

    if (getThemeMode()) {
      dispatch(setThemeModeAction(getThemeMode().themeMode));
    }
  }, [dispatch]);

  //we use this for dynamically change the color wth changing state
  const dynamicTheme = React.useMemo(
    () =>
      createTheme(
        setTheme(
          props.system.themeColor,
          props.system.themeMode,
          props.system.themeDir
        )
      ),
    [props.system.themeColor, props.system.themeMode, props.system.themeDir]
  );

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ width: "100%", textAlign: "center" }}>
          <StylesProvider jss={jss}>
            <ThemeProvider theme={dynamicTheme}>
              <CssBaseline />
              <Box
                alignItems='center'
                justifyContent='center'
                dir={props.system.themeDir}>
                <BrowserRouter>
                  <SnackBarMessage />
                  <CustomAppBar />
                  <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/settings/' element={<Settings />} />
                    <Route exact path='/settings/login/' element={<Login />} />
                    <Route
                      exact
                      path='/settings/login/check/:phone'
                      element={<CheckOTP />}
                    />
                    <Route exact path='/dashboard/' element={<Dashboard />} />
                    <Route
                      exact
                      path='/dashboard/builder/:appid/'
                      element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/dashboard/builder/:appid/'
                        element={<Builder />}
                      />
                    </Route>

                    {/* Route for creating new App inside dashboard */}
                    <Route
                      exact
                      path='/dashboard/create/'
                      element={<PrivateRoute />}>
                      <Route
                        exact
                        path='/dashboard/create/'
                        element={<NewApp />}
                      />
                    </Route>
                  </Routes>
                  <CustomBottomNavigation />
                </BrowserRouter>
              </Box>
            </ThemeProvider>
          </StylesProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default connect(MapStateToProps)(App);
