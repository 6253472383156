import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { initAppAction } from "../../../store/actions/builderAction";
import { getChoises, nextElement } from "../Widgets/Choises";
import { getAuthToken } from "../../../internal/authToken";
import * as snackbarActions from "../../../store/actions/snackbarActions";
import { config } from "../../../config/Constants";

import {
  editNodeAction,
  setCurrentWidget,
} from "../../../store/actions/builderAction";

var items;
var currentBlock;
var blockContent;
var target;

const MapStateToProps = (state) => {
  return { builder: state.builderReducer };
};

function Chart(props) {
  const dispatch = useDispatch();
  const [fetchingList, setFetchingList] = React.useState(true);
  const [appLoaded, setAppLoaded] = React.useState(false);
  let { appid } = useParams();

  if (props.builder.app && props.builder.app.name && appLoaded) {
    if (
      props.builder.getCurrentBlock &&
      props.builder.app &&
      props.builder.app.structure &&
      props.builder.app.structure[0] &&
      props.builder.getCurrentBlock !== props.builder.app.structure[0].id
    ) {
      //inside an specific block
      currentBlock = props.builder.getCurrentBlock;
      let obj = props.builder.app.structure.find((o) => o.id === currentBlock);
      if (obj) {
        blockContent = obj.blockItems;
        target = obj.next;
      }
    } else {
      //The main block
      if (
        props.builder.app &&
        props.builder.app.structure &&
        props.builder.app.structure[0] &&
        props.builder.app.structure[0].id
      ) {
        blockContent = props.builder.app.structure;
        currentBlock = props.builder.app.structure[0].id;
        target = props.builder.app.structure[0].id;
      }
    }
  }

  useEffect(() => {
    var userApp = null;
    dispatch(initAppAction({}));

    if (getAuthToken() && getAuthToken().token && appid) {
      getApp();
    }

    closeEditItemPanel();

    function clearWidgetSelectStore() {
      dispatch(setCurrentWidget(""));
    }

    function closeEditItemPanel() {
      dispatch(
        editNodeAction({
          openDialog: "none",
          addNew: null,
          parentId: null,
        })
      );

      clearWidgetSelectStore();
    }

    function getApp() {
      const rawData = { token: getAuthToken().token, appid: appid };
      setFetchingList(true);
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: rawData,
        url: config.url.REPLY_SERVER + "/api/v1/apps/app",
      })
        .then(function (res) {
          setFetchingList(false);
          if (res.data && res.data.response) {
            if (res.data.response.status === 1) {
              userApp = res.data.response.app.appJson;
              if (userApp) {
                dispatch(initAppAction(userApp));
                setAppLoaded(true);
              }
            } else if (
              res.data.response.status === 0 &&
              res.data.response.errors != null
            ) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          setFetchingList(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Error fetching Apps(130)",
              severity: "error",
              autoHide: true,
            })
          );
        });
    }

    // var start = {
    //   name: "login",
    //   type: "app",
    //   active: 1,
    //   structure: [
    //     {
    //       id: "2767482364r27934792874hf82347r",
    //       type: "start",
    //       next: "478628346r829436yh878yh2783r434",
    //       parentBlock: "2767482364r27934792874hf82347r",
    //       label: "start",
    //     },
    //     {
    //       id: "478628346r829436yh878yh2783r434",
    //       type: "end",
    //       next: "",
    //       parentBlock: "478628346r829436yh878yh2783r434",
    //       label: "end",
    //     },
    //   ],
    // };

    // dispatch(initAppAction(userApp));
  }, [dispatch, appid]);

  if (props.builder.app && props.builder.app.name && blockContent) {
    items = getChoises(blockContent, target);
  }

  return (
    <Grid
      container
      justify='center'
      spacing={0}
      direction='column'
      alignItems='center'
      sx={{ backgroundColor: "background.default" }}
      justifyContent='center'>
      <Grid justify='center' item xs={12} sx={{ minWidth: "100%" }}>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          spacing={3}
          direction='column'
          justify='center'
          sx={{
            padding: 10,
            display: "block",
            overflow: "auto",
            minHeight: "85vh",
            minWidth: "100vw",
            backgroundColor: "background.default",
          }}>
          {fetchingList === true ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              spacing={3}
              direction='column'
              justify='center'
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
              }}>
              <Skeleton variant='circular' width={80} height={80} />
            </Box>
          ) : items ? (
            nextElement(items)
          ) : (
            <></>
          )}
          {/* {items ? nextElement(items) : <></>} */}
        </Box>
      </Grid>
    </Grid>
  );
}

export default connect(MapStateToProps)(Chart);
