import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import SelectSay from "../Widgets/Select/Say";
import SelectIf from "../Widgets/Select/If";
import SelectQuestion from "../Widgets/Select/Question";
import SelectBlock from "../Widgets/Select/Block";
import SelectParagraph from "../Widgets/Select/Paragraph";
import SelectVideo from "../Widgets/Select/Video";

import {
  editNodeAction,
  setCurrentWidget,
} from "../../../store/actions/builderAction";

const MapStateToProps = (state) => {
  return {
    message: state.newMessageReducer,
    errors: state.errorReducer,
    builder: state.builderReducer,
  };
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, displayClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            display: props.displayClose,
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function EditDialog(props) {
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      dispatch(
        editNodeAction({
          openDialog: "none",
          addNew: null,
          parentId: null,
        })
      );

      clearWidgetSelectStore();
    }
  };

  useEffect(() => {
    //when we want to edit an item and we click on itself
    if (props.edit && props.edit.editItem === true && props.edit.targetWidget) {
      dispatch(setCurrentWidget(props.edit.targetWidget));
    }
  }, [props.edit, dispatch]);

  function clearWidgetSelectStore() {
    dispatch(setCurrentWidget(""));
  }

  const handleWidgetSelectChange = (event) => {
    if (event && event.target.value) {
      clearWidgetSelectStore();
      dispatch(setCurrentWidget(event.target.value));
    }
  };

  function renderSelectSwitch() {
    if (props.edit && props.builder.getCurrentWidget) {
      switch (props.builder.getCurrentWidget) {
        case "say":
          return (
            <SelectSay
              parent={props.edit.parentId}
              edit={props.edit}
              clickOnPlus={props.edit.clickOnPlus}
              isSubWidget={props.edit.isSubWidget}
              subType={props.edit.subType}
              subParentType={props.edit.subParentType}
              isAfterComplexWidget={props.edit.isAfterComplexWidget}
              complexWidgetType={props.edit.complexWidgetType}
              complexWidgetNext={props.edit.complexWidgetNext}
              parentNext={props.edit.parentNext}
            />
          );

        case "paragraph":
          return (
            <SelectParagraph
              parent={props.edit.parentId}
              edit={props.edit}
              clickOnPlus={props.edit.clickOnPlus}
              isSubWidget={props.edit.isSubWidget}
              subType={props.edit.subType}
              subParentType={props.edit.subParentType}
              isAfterComplexWidget={props.edit.isAfterComplexWidget}
              complexWidgetType={props.edit.complexWidgetType}
              complexWidgetNext={props.edit.complexWidgetNext}
              parentNext={props.edit.parentNext}
            />
          );

        case "if":
          return (
            <SelectIf
              parent={props.edit.parentId}
              edit={props.edit}
              clickOnPlus={props.edit.clickOnPlus}
              isSubWidget={props.edit.isSubWidget}
              subType={props.edit.subType}
              subParentType={props.edit.subParentType}
              isAfterComplexWidget={props.edit.isAfterComplexWidget}
              complexWidgetType={props.edit.complexWidgetType}
              complexWidgetNext={props.edit.complexWidgetNext}
              parentNext={props.edit.parentNext}
            />
          );

        case "question":
          return (
            <SelectQuestion
              parent={props.edit.parentId}
              edit={props.edit}
              clickOnPlus={props.edit.clickOnPlus}
              isSubWidget={props.edit.isSubWidget}
              subType={props.edit.subType}
              subParentType={props.edit.subParentType}
              isAfterComplexWidget={props.edit.isAfterComplexWidget}
              complexWidgetType={props.edit.complexWidgetType}
              complexWidgetNext={props.edit.complexWidgetNext}
              parentNext={props.edit.parentNext}
            />
          );

        case "video":
          return (
            <SelectVideo
              parent={props.edit.parentId}
              edit={props.edit}
              clickOnPlus={props.edit.clickOnPlus}
              isSubWidget={props.edit.isSubWidget}
              subType={props.edit.subType}
              subParentType={props.edit.subParentType}
              isAfterComplexWidget={props.edit.isAfterComplexWidget}
              complexWidgetType={props.edit.complexWidgetType}
              complexWidgetNext={props.edit.complexWidgetNext}
              parentNext={props.edit.parentNext}
            />
          );

        case "block":
          return (
            <SelectBlock
              parent={props.edit.parentId}
              edit={props.edit}
              clickOnPlus={props.edit.clickOnPlus}
              isSubWidget={props.edit.isSubWidget}
              subType={props.edit.subType}
              subParentType={props.edit.subParentType}
              isAfterComplexWidget={props.edit.isAfterComplexWidget}
              complexWidgetType={props.edit.complexWidgetType}
              complexWidgetNext={props.edit.complexWidgetNext}
              parentNext={props.edit.parentNext}
              parentBlockNext={props.edit.parentBlockNext}
            />
          );
        default:
          return "";
      }
    }
  }

  const widgetSelector = () => {
    var items = [
      <MenuItem value={"say"} key='widget_say'>
        Say
      </MenuItem>,
      <MenuItem value={"paragraph"} key='widget_paragraph'>
        Paragraph
      </MenuItem>,
      <MenuItem value={"question"} key='widget_question'>
        Question
      </MenuItem>,
      <MenuItem value={"video"} key='widget_video'>
        Video
      </MenuItem>,
    ];

    //if we are inside an specific block, not the main block then we show if widget.
    //because we don't want to add if widget as the first widget of a block
    //so if we click on a empty block, we don't se if widget to select in EditPanel.jsx
    if (props.builder.getCurrentBlock) {
      items.push(
        <MenuItem value={"if"} key='widget_if'>
          If
        </MenuItem>
      );
    }
    return items;
  };

  const mainBlockSelector = () => {
    const items = [
      <MenuItem value={"block"} key='widget_block'>
        Block
      </MenuItem>,
    ];
    return items;
  };

  return (
    <BootstrapDialog
      fullWidth
      maxWidth='xs'
      transitionDuration={{
        enter: 0,
        exit: 0,
      }}
      onClose={handleClose}
      aria-labelledby='Edit Dialog Title'
      open={props.edit && props.edit.openDialog === "block" ? true : false}>
      <BootstrapDialogTitle
        id='customized-dialog-title'
        onClose={handleClose}
        displayClose={props.builder.builderDialogIsBusy ? "none" : "block"}>
        {props.edit ? (props.edit.addNew ? "Add New Item" : "Edit Item") : ""}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            overflowY: "scroll",
            height: "250px",
            paddingRight: "50px",
            paddingLeft: "50px",
          }}>
          <br />
          <FormControl fullWidth>
            <InputLabel id='select-widget-label'>Widgets</InputLabel>
            <Select
              disabled={
                props.edit && props.edit.editItem
                  ? true
                  : props.builder.builderDialogIsBusy
                  ? true
                  : false
              }
              labelId='select-widget-label'
              id='select-widget-id'
              value={props.builder.getCurrentWidget}
              onChange={handleWidgetSelectChange}
              label='Widgets'>
              {!props.builder.getCurrentBlock
                ? props.edit && props.edit.clickOnPlus
                  ? mainBlockSelector()
                  : widgetSelector()
                : widgetSelector()}
            </Select>
          </FormControl>
          <br />
          <br />
          <Box>{renderSelectSwitch()}</Box>
          <br />
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}
export default connect(MapStateToProps)(EditDialog);
