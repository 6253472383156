import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import axios from "axios";

import {
  addNewItemAction,
  setCurrentWidget,
  editNodeAction,
  editItemAction,
  deleteItemAction,
  setBuilderDialogIsBusy,
} from "../../../../store/actions/builderAction";

import { getAuthToken } from "../../../../internal/authToken";
import * as snackbarActions from "../../../../store/actions/snackbarActions";
import { config } from "../../../../config/Constants";
import { GetItemInfo } from "../../../../internal/buildApp";

const MapStateToProps = (state) => {
  return { builder: state.builderReducer, system: state.systemReducer };
};

var video;
var item;

function SelectVideo(props) {
  const dispatch = useDispatch();
  const [videoURL, setVideoURL] = React.useState("");
  const [videoURLError, setVideoURLError] = React.useState(false);
  const [videoDesc, setVideoDesc] = React.useState("");
  const [videoDescError, setVideoDescError] = React.useState(false);
  const [videoLabel, setVideoLabel] = React.useState("");
  const [videoLabelError, setVideoLabelError] = React.useState(false);
  const [newLoaded, setNewLoaded] = React.useState(false);
  const [isNewItem, setisNewItem] = React.useState(true);

  useEffect(() => {
    //check if we load data for the first time or not. if we dont check, whole process renders every time
    if (!newLoaded) {
      setNewLoaded(true);
      //ٌwhen we want to edit an item, not adding new one
      if (props.edit && props.edit.editItem && props.edit.targetWidget) {
        setisNewItem(false);
        item = GetItemInfo(props.builder.app, props.edit.itemId);
        if (item) {
          video = item;
          if (item.url) {
            setVideoURL(item.url);
          }

          if (item.label) {
            setVideoLabel(item.label);
          }

          if (item.desc) {
            setVideoDesc(item.desc);
          }
        }
      }
    }

    if (isNewItem && props.edit && !props.edit.editItem) {
      //if we want to create new video node
      video = {
        id: uuidv4(),
        type: "video",
        next: "",
        parentBlock: "",
        outputType: "video",
        url: videoURL,
        desc: videoDesc,
        label: videoLabel,
        parent: "",
      };
    }
  }, [
    videoURL,
    videoDesc,
    videoLabel,
    props.edit,
    props.builder.app,
    newLoaded,
    isNewItem,
  ]);

  function clearWidgetSelectStore() {
    dispatch(setCurrentWidget(""));
  }

  function closeEditItemPanel() {
    dispatch(
      editNodeAction({
        openDialog: "none",
        addNew: null,
        parentId: null,
      })
    );
    clearWidgetSelectStore();
  }

  function setVideoURLFunc(e) {
    let val = e.target.value;
    if (val && val.length > 0) {
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setVideoURL(val);
        if (videoURL && videoURL.length > 0) {
          setVideoURLError(false);
        }
      }
    } else {
      setVideoURL("");
    }
  }

  function setVideoDescFunc(e) {
    let val = e.target.value;
    if (val && val.length > 0) {
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setVideoDesc(val);
        if (videoDesc && videoDesc.length > 0) {
          setVideoDescError(false);
        }
      }
    } else {
      setVideoDesc("");
    }
  }

  function setVideoLabelFunc(e) {
    let val = e.target.value;
    if (val && val.length > 0) {
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setVideoLabel(val);
        if (videoLabel && videoLabel.length > 0) {
          setVideoLabelError(false);
        }
      }
    } else {
      setVideoLabel("");
    }
  }

  function addNewItem() {
    if (video) {
      video.url = videoURL;
      video.desc = videoDesc;
      video.label = videoLabel;
    }
    if (
      video &&
      video.id &&
      video.type === "video" &&
      video.url &&
      video.url.length > 0 &&
      video.label &&
      video.label.length > 0 &&
      props.edit &&
      video.desc === videoDesc &&
      video.url === videoURL &&
      video.label === videoLabel
    ) {
      if (isNewItem) {
        //if we want to add new item
        dispatch(
          addNewItemAction({
            item: video,
            config: props.edit,
            clickOnPlus: props.clickOnPlus,
            isSubWidget: props.isSubWidget,
            subType: props.subType,
            subParentType: props.subParentType,
            isAfterComplexWidget: props.isAfterComplexWidget,
            complexWidgetType: props.complexWidgetType,
            complexWidgetNext: props.complexWidgetNext,
            parentNext: props.parentNext,
            parentBlock: props.parentBlock,
          })
        );

        dispatchBuilderDialogIsBusyAction(true);
      } else {
        //if we want to edit an item
        dispatch(
          editItemAction({
            item: video,
          })
        );
        dispatchBuilderDialogIsBusyAction(true);
      }
      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    } else {
      if ((video && !video.url) || video.url.length === 0) {
        setVideoURLError(true);
      }

      if ((video && !video.label) || video.label.length === 0) {
        setVideoLabelError(true);
      }
    }
  }

  function dispatchBuilderDialogIsBusyAction(value) {
    dispatch(setBuilderDialogIsBusy(value));
  }

  function deleteItem() {
    if (item && item.id) {
      dispatch(
        deleteItemAction({
          item: item,
        })
      );
      dispatchBuilderDialogIsBusyAction(true);

      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    }
  }

  function saveApptoServer(app) {
    if (app && app.name && getAuthToken().token) {
      const rawData = {
        token: getAuthToken().token,
        appUsername: app.name,
        app: JSON.stringify(app),
      };
      axios({
        method: "post",
        url: config.url.REPLY_SERVER + "/api/v1/apps/saveapp",
        data: rawData,
        timeout: 15000,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (res.data && res.data.response.status === 1) {
            dispatchBuilderDialogIsBusyAction(false);
            closeEditItemPanel();
          } else {
            if (res.data.response.errors) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
              dispatchBuilderDialogIsBusyAction(false);
            }
          }
        })
        .catch(function (res) {
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Action not saved",
              severity: "error",
              autoHide: true,
            })
          );
          dispatchBuilderDialogIsBusyAction(false);
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Bad request",
          severity: "error",
          autoHide: true,
        })
      );
      dispatchBuilderDialogIsBusyAction(false);
    }
  }

  return (
    <Stack
      spacing={3}
      disabled={props.builder.builderDialogIsBusy ? true : false}>
      <Box>
        <TextField
          id='outlined-basic'
          error={videoURLError}
          label='Video URL'
          variant='outlined'
          onChange={setVideoURLFunc}
          value={videoURL}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 2000 }}
        />
      </Box>

      <Box>
        <TextField
          id='outlined-basic'
          error={videoDescError}
          label='Description'
          variant='outlined'
          onChange={setVideoDescFunc}
          value={videoDesc}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 1000 }}
        />
      </Box>

      <Box>
        <TextField
          id='outlined-basic'
          error={videoLabelError}
          label='Label'
          variant='outlined'
          onChange={setVideoLabelFunc}
          value={videoLabel}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 30 }}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Button
          variant='contained'
          fullWidth
          onClick={() => addNewItem()}
          disabled={
            props.builder.builderDialogIsBusy
              ? true
              : videoURL[0] && videoLabel
              ? false
              : true
          }>
          Save
        </Button>
      </Box>

      {!isNewItem ? (
        <Box sx={{ width: "100%" }}>
          <Button
            variant='contained'
            color='error'
            fullWidth
            onClick={() => deleteItem()}
            disabled={props.builder.builderDialogIsBusy ? true : false}>
            Remove
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
}
export default connect(MapStateToProps)(SelectVideo);
