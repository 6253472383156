import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { connect, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";

import { editNodeAction } from "../../../store/actions/builderAction";
import { getChoises, nextElement } from "./Choises";

var ifTrueItems;
var ifFalseItems;
var items;

const MapStateToProps = (state) => {
  return { builder: state.builderReducer };
};

function If(props) {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  function addNewItem() {
    if (props.item.id) {
      if (!props.builder.editNode) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlock: props.item.parentBlock,
            clickOnPlus: true,
            isAfterComplexWidget: true,
            complexWidgetType: "if",
            complexWidgetNext: props.item.next,
            isSubWidget: props.item.isSubWidget,
            subParentType: props.item.subParentType,
            subType: props.item.subType,
          })
        );
      } else if (
        props.builder.editNode &&
        props.builder.editNode.openDialog === "none"
      ) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlock: props.item.parentBlock,
            clickOnPlus: true,
            isAfterComplexWidget: true,
            complexWidgetType: "if",
            complexWidgetNext: props.item.next,
            isSubWidget: props.item.isSubWidget,
            subParentType: props.item.subParentType,
            subType: props.item.subType,
          })
        );
      }
    }
  }

  function addNewSuccessItem() {
    if (props.item.id) {
      if (!props.builder.editNode) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlock: props.item.parentBlock,
            clickOnPlus: true,
            isSubWidget: true,
            subParentType: "if",
            subType: "true",
          })
        );
      } else if (
        props.builder.editNode &&
        props.builder.editNode.openDialog === "none"
      ) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlock: props.item.parentBlock,
            clickOnPlus: true,
            isSubWidget: true,
            subParentType: "if",
            subType: "true",
          })
        );
      }
    }
  }

  function addNewFailItem() {
    if (props.item.id) {
      if (!props.builder.editNode) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlock: props.item.parentBlock,
            clickOnPlus: true,
            isSubWidget: true,
            subParentType: "if",
            subType: "false",
          })
        );
      } else if (
        props.builder.editNode &&
        props.builder.editNode.openDialog === "none"
      ) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlock: props.item.parentBlock,
            clickOnPlus: true,
            isSubWidget: true,
            subParentType: "if",
            subType: "false",
          })
        );
      }
    }
  }

  function goToEdit() {
    dispatch(
      editNodeAction({
        openDialog: "block",
        editItem: true,
        itemId: props.item.id,
        targetWidget: "if",
      })
    );
  }

  if (props && props.blockContent && props.target) {
    ifTrueItems = getChoises(props.blockContent, props.ifTrueTarget);
    ifFalseItems = getChoises(props.blockContent, props.ifFalseTarget);
    items = getChoises(props.blockContent, props.target);
  }

  return (
    <Stack
      direction='column'
      alignItems='center'
      justify='center'
      sx={{ textAlign: "center", height: "100%" }}>
      <Button
        disableRipple
        disableElevation
        variant='contained'
        sx={{
          backgroundColor: "#9484d4",
          transform: "rotate(45deg)",
          width: "7vw",
          height: "7vw",

          margin: "1vw 0",
          "&:hover": {
            backgroundColor: "#7c69c7",
          },
        }}
        onClick={() => goToEdit()}>
        <Box sx={{ transform: "rotate(-45deg)" }}>{props.item.label}</Box>
      </Button>
      <Box
        sx={{
          borderLeft: "1px solid #a8a7a7ff",
          minHeight: "20px",
        }}></Box>

      <Stack direction='row'>
        {/* then part */}
        <Box sx={{ textAlign: "start" }}>
          <Stack
            direction='column'
            alignItems='center'
            justify='center'
            sx={{ textAlign: "center", height: "100%" }}>
            <Box
              sx={{
                textAlign: "end",
                height: 0,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}>
              {/* horizontal line */}
              <Box
                sx={{
                  borderBottom: "1px solid #a8a7a7ff",
                  width: "50%",
                }}></Box>
            </Box>

            <Stack
              direction='column'
              alignItems='center'
              justify='center'
              sx={{ textAlign: "center" }}>
              <Box sx={{ minHeight: "20px", display: "flex" }}>
                <Box
                  sx={{
                    borderLeft: "1px solid #a8a7a7ff",
                    minHeight: "20px",
                  }}></Box>
              </Box>
              <Box>
                <Button
                  disableRipple
                  disableElevation
                  variant='contained'
                  sx={{
                    backgroundColor: "#aa9ede",
                    "&:hover": {
                      backgroundColor: "#7c69c7",
                    },
                  }}>
                  Then
                </Button>
              </Box>
            </Stack>

            <Stack
              direction='column'
              alignItems='center'
              justify='center'
              sx={{
                textAlign: "center",
                height: "100%",
                width: "100%",
                display: "flex",
              }}>
              <Box
                sx={{
                  borderLeft: "1px solid #a8a7a7ff",
                  minHeight: "20px",
                }}></Box>
              <AddCircleIcon
                sx={{ color: "#787877" }}
                onClick={() => addNewSuccessItem()}
              />
              <Stack
                direction='column'
                alignItems='center'
                justify='center'
                sx={{ textAlign: "center", height: "100%", width: "100%" }}>
                <Box
                  sx={{
                    borderLeft: "1px solid #a8a7a7ff",
                    minHeight: "20px",
                  }}></Box>
                {ifTrueItems ? nextElement(ifTrueItems, props.item.id) : <></>}
                <Stack
                  direction='column'
                  alignItems='center'
                  justify='center'
                  sx={{ textAlign: "center", height: "100%", width: "100%" }}>
                  <Box
                    sx={{
                      borderLeft: "1px solid #a8a7a7ff",
                      height: "100%",
                    }}></Box>
                  <Box
                    sx={{
                      textAlign: "end",
                      height: "1px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}>
                    {/* horizontal line */}
                    <Box
                      sx={{
                        borderBottom: "1px solid #a8a7a7ff",
                        height: "1px",
                        width: "50%",
                      }}></Box>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        {/* center placeholder */}
        <Stack
          direction='column'
          alignItems='center'
          justify='center'
          sx={{ display: "flex" }}>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              width: "100%",
              minWidth: "200px",
              justifyContent: "flex-center",
            }}>
            {/* horizontal line */}
            <Box
              sx={{
                borderBottom: "1px solid #a8a7a7ff",
                width: "100%",
              }}></Box>
          </Box>
          <Box sx={{ height: "100%", display: "flex" }}></Box>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              width: "100%",
              justifyContent: "flex-center",
            }}>
            {/* horizontal line */}
            <Box
              sx={{
                borderBottom: "1px solid #a8a7a7ff",
                width: "100%",
              }}></Box>
          </Box>
        </Stack>
        {/* else part */}
        <Box sx={{ textAlign: "end" }}>
          <Stack
            direction='column'
            alignItems='center'
            justify='center'
            sx={{ textAlign: "center", height: "100%" }}>
            {/* horizontal line */}
            <Box
              sx={{
                textAlign: "start",
                height: 0,
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}>
              <Box
                sx={{
                  borderBottom: "1px solid #a8a7a7ff",
                  width: "50%",
                }}></Box>
            </Box>

            <Stack
              direction='column'
              alignItems='center'
              justify='center'
              sx={{ textAlign: "center" }}>
              {/* vertical line above ELSE block */}
              <Box sx={{ minHeight: "20px" }}>
                <Box
                  sx={{
                    borderLeft: "1px solid #a8a7a7ff",
                    minHeight: "20px",
                  }}></Box>
              </Box>
              {/* ELSE block */}
              <Box>
                <Button
                  disableRipple
                  disableElevation
                  variant='contained'
                  sx={{
                    backgroundColor: "#aa9ede",
                    "&:hover": {
                      backgroundColor: "#7c69c7",
                    },
                  }}>
                  Else
                </Button>
              </Box>
            </Stack>

            {/* Below ELSE block */}
            <Stack
              direction='column'
              alignItems='center'
              justify='center'
              sx={{ textAlign: "center", height: "100%", width: "100%" }}>
              <Box
                sx={{
                  borderLeft: "1px solid #a8a7a7ff",
                  minHeight: "20px",
                }}></Box>
              <AddCircleIcon
                sx={{ color: "#787877" }}
                onClick={() => addNewFailItem()}
              />
              <Stack
                direction='column'
                alignItems='center'
                justify='center'
                sx={{ textAlign: "center", height: "100%", width: "100%" }}>
                <Box
                  sx={{
                    borderLeft: "1px solid #a8a7a7ff",
                    minHeight: "20px",
                  }}></Box>

                {ifFalseItems ? (
                  nextElement(ifFalseItems, props.item.id)
                ) : (
                  <></>
                )}
                <Stack
                  direction='column'
                  alignItems='center'
                  justify='center'
                  sx={{ textAlign: "center", height: "100%", width: "100%" }}>
                  <Box
                    sx={{
                      borderLeft: "1px solid #a8a7a7ff",
                      height: "100%",
                    }}></Box>
                  <Box
                    sx={{
                      textAlign: "start",
                      height: "1px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}>
                    {/* horizontal line */}
                    <Box
                      sx={{
                        borderBottom: "1px solid #a8a7a7ff",
                        height: "1px",
                        width: "50%",
                      }}></Box>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <Box sx={{ textAlign: "center" }}>
        {/* center placeholder */}

        <Stack
          direction='column'
          alignItems='center'
          justify='center'
          sx={{ textAlign: "center" }}>
          <Box
            sx={{
              borderLeft: "1px solid #a8a7a7ff",
              minHeight: "20px",
            }}></Box>
          <AddCircleIcon
            sx={{ color: "#787877" }}
            onClick={() => addNewItem()}
          />
          <Box
            sx={{
              borderLeft: "1px solid #a8a7a7ff",
              minHeight: "20px",
            }}></Box>
        </Stack>
        {items ? nextElement(items, props.item.id, "afterIf") : <></>}
      </Box>
    </Stack>
  );
}

export default connect(MapStateToProps)(If);
