import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";

import { toEnNumber, isNumber } from "../../../utils/Validations";
import * as snackbarActions from "../../../store/actions/snackbarActions";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userNumber, setUserNumber] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true);

  // const dispatch = useDispatch();

  useEffect(() => {}, []);

  function handleUserNumberChange(event) {
    let val = event.target.value;
    let enNumber = toEnNumber(val);

    if (
      isNumber(enNumber) &&
      val.length < 16 &&
      val.length > 10 &&
      (enNumber.substring(0, 1) === "+" || enNumber.substring(0, 1) === "0")
    ) {
      setUserNumber(enNumber);
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }

  function handleSendSMSClick(event) {
    if (event) {
      event.preventDefault();
    }

    if (
      userNumber !== null &&
      userNumber !== "" &&
      userNumber.length > 10 &&
      userNumber.length < 16
    ) {
      const rawData = {
        mobile: userNumber,
        lng: "en",
        client: "webbuilder",
        client_version: "1.0",
      };
      axios({
        method: "post",
        url: config.url.ACCOUNTS_SERVER + "/api/v1/auth/getotp",
        data: rawData,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (res.data && res.data.response.status === 1) {
            navigate("/settings/login/check/" + userNumber);
          } else {
            dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: res.data.response.errors.errorContent,
                severity: "error",
                autoHide: true,
              })
            );
          }
        })
        .catch(function (res) {
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Bad request",
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Phone number is empty",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Grid item xs={12} sx={{ backgroundColor: "background.default" }}>
      <Box
        sx={{
          marginTop: "30px",
          minWidth: "50%",
          display: "inline-block",
        }}>
        <Stack>
          <Stack sx={{ textAlign: "start !important" }}>
            <Box>
              <Typography
                variant='h3'
                component='div'
                gutterBottom
                sx={{
                  color: "primary.title",
                }}>
                Hello,
              </Typography>
            </Box>

            <Typography
              variant='body1'
              component='div'
              gutterBottom
              sx={{
                marginTop: "-25px",
                color: "primary.subtitle",
              }}>
              Please enter your mobile phone number
            </Typography>
          </Stack>
          <form autoComplete='off'>
            <TextField
              id='outlined-basic'
              InputLabelProps={{ shrink: false }}
              inputProps={{
                maxLength: 15,
                style: {
                  textAlign: "center",
                  direction: "ltr",
                  flip: "false",
                },
              }}
              sx={{ marginTop: "20px" }}
              type='text'
              required
              margin='normal'
              placeholder='Mobile Number*'
              fullWidth={true}
              variant='outlined'
              onChange={handleUserNumberChange}
            />
            <Button
              variant='contained'
              fullwidth='true'
              disabled={btnDisabled}
              disableElevation={true}
              onClick={() => handleSendSMSClick()}
              sx={{ minWidth: "100%" }}>
              Check
            </Button>
          </form>
        </Stack>
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(Login);
