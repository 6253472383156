const websocketInitialState = {};

const websocketReducer = (state = { ...websocketInitialState }, action) => {
  switch (action.type) {
    case "WS_CONNECT":
      return { ...state, host: action.host };

    case "WS_CONNECTING":
      return { ...state, status: action.status };

    default:
      return state;
  }
};

export default websocketReducer;
