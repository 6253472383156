import * as React from "react";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate, useLocation } from "react-router-dom";
import { matchPath } from "react-router-dom";

function CustomBottomNavigation(props) {
  const [value, setValue] = React.useState(-1);
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();

  useEffect(() => {
    if (matchPath({ path: "/", end: false }, location.pathname)) {
      setValue(0);
    }
    if (matchPath({ path: "/dashboard/", end: false }, location.pathname)) {
      setValue(1);
    }
    if (matchPath({ path: "/settings/", end: false }, location.pathname)) {
      setValue(2);
    }
  }, [location.pathname]);

  return (
    <Box
      alignItems='center'
      justifyContent='center'
      sx={{
        position: "fixed",
        width: "100%",
        bottom: 0,
        left: 0,
        right: 0,
        alignContent: "center",
        alignItems: "center",
      }}>
      <BottomNavigation
        sx={{ backgroundColor: "background.default" }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          switch (newValue) {
            case 0:
              navigate("/");
              break;

            case 1:
              navigate("/dashboard/");
              break;

            case 2:
              navigate("/settings/");
              // dispatch(
              //   setUserColorAction({ userColor: "#ff44aa", themeMode: "dark" })
              // );
              break;

            default:
              break;
          }
        }}>
        <BottomNavigationAction label='Home' icon={<HomeIcon />} />
        <BottomNavigationAction label='Dashboard' icon={<DashboardIcon />} />
        <BottomNavigationAction label='Settings' icon={<SettingsIcon />} />
      </BottomNavigation>
    </Box>
  );
}

export default CustomBottomNavigation;
