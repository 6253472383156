import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { connect, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";

import { editNodeAction } from "../../../store/actions/builderAction";
import { getChoises, nextElement } from "./Choises";

var items;

const MapStateToProps = (state) => {
  return { builder: state.builderReducer };
};

function Paragraph(props) {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  function addNewItem() {
    if (props.item.id) {
      if (!props.builder.editNode) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlock: props.item.parentBlock,
            clickOnPlus: true,
            isSubWidget: props.item.isSubWidget,
            subParentType: props.item.subParentType,
            subType: props.item.subType,
          })
        );
      } else if (
        props.builder.editNode &&
        props.builder.editNode.openDialog === "none"
      ) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlock: props.item.parentBlock,
            clickOnPlus: true,
            isSubWidget: props.item.isSubWidget,
            subParentType: props.item.subParentType,
            subType: props.item.subType,
          })
        );
      }
    }
  }

  function goToEdit() {
    dispatch(
      editNodeAction({
        openDialog: "block",
        editItem: true,
        itemId: props.item.id,
        targetWidget: "paragraph",
      })
    );
  }

  if (props && props.blockContent && props.target) {
    items = getChoises(props.blockContent, props.target);
  }

  return (
    <Stack direction='column' alignItems='center' justify='center'>
      <Button
        variant='contained'
        disableRipple
        disableElevation
        sx={{
          backgroundColor: "#64d48c",
          "&:hover": {
            backgroundColor: "#47ba6f",
          },
        }}
        onClick={() => goToEdit()}>
        {props.item.label}
      </Button>
      <Box
        sx={{
          borderLeft: "1px solid #a8a7a7ff",
          minHeight: "20px",
        }}></Box>
      <AddCircleIcon sx={{ color: "#787877" }} onClick={() => addNewItem()} />
      <Box
        sx={{
          borderLeft: "1px solid #a8a7a7ff",
          minHeight: "20px",
        }}></Box>
      {items ? nextElement(items, props.item.parent) : <></>}
    </Stack>
  );
}

export default connect(MapStateToProps)(Paragraph);
