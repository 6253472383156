import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { connect, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";

import { CheckBlock } from "../../../internal/buildApp";
import {
  setCurrentBlock,
  editNodeAction,
  setCurrentWidget,
} from "../../../store/actions/builderAction";
import { getChoises, nextElement } from "./Choises";

var items;

const MapStateToProps = (state) => {
  return { builder: state.builderReducer };
};

function Block(props) {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  function clearWidgetSelectStore() {
    dispatch(setCurrentWidget(""));
  }

  function closeEditItemPanel() {
    dispatch(
      editNodeAction({
        openDialog: "none",
        addNew: null,
        parentId: null,
      })
    );

    clearWidgetSelectStore();
  }

  function addNewItemInside() {
    var ifBlockNotEmpty = CheckBlock(props.blockContent, props.item.id);
    if (
      ifBlockNotEmpty &&
      props.builder.editNode &&
      props.builder.editNode.openDialog === "none"
    ) {
      closeEditItemPanel();
      dispatch(setCurrentBlock(props.item.id));
    } else {
      if (
        props.item.id &&
        props.builder.editNode &&
        props.builder.editNode.openDialog === "none"
      ) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlockNext: props.item.blockNext,
            parentBlock: props.item.parentBlock,
            clickOnPlus: false,
          })
        );
      }
    }
  }

  function addNewItem() {
    if (props.item.id) {
      if (!props.builder.editNode) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlockNext: props.item.blockNext,
            clickOnPlus: true,
            isSubWidget: props.item.isSubWidget,
            subParentType: props.item.subParentType,
            subType: props.item.subType,
          })
        );
      } else if (
        props.builder.editNode &&
        props.builder.editNode.openDialog === "none"
      ) {
        dispatch(
          editNodeAction({
            openDialog: "block",
            addNew: true,
            parentId: props.item.id,
            parentNext: props.item.next,
            parentBlockNext: props.item.blockNext,
            clickOnPlus: true,
            isSubWidget: props.item.isSubWidget,
            subParentType: props.item.subParentType,
            subType: props.item.subType,
          })
        );
      }
    }
  }

  if (props && props.builder.app && props.blockContent && props.target) {
    items = getChoises(props.blockContent, props.target);
  }

  return (
    <Stack direction='column' alignItems='center' justify='center'>
      <Button
        onClick={() => addNewItemInside()}
        variant='contained'
        disableRipple
        disableElevation
        sx={{
          maxWidth: "200px",
          maxHeight: "100px",
          minWidth: "200px",
          minHeight: "100px",
        }}>
        {props.item.label}
      </Button>
      <Box
        sx={{
          borderLeft: "1px solid #a8a7a7ff",
          minHeight: "20px",
        }}></Box>
      <AddCircleIcon sx={{ color: "#787877" }} onClick={() => addNewItem()} />
      <Box
        sx={{
          borderLeft: "1px solid #a8a7a7ff",
          minHeight: "20px",
        }}></Box>
      {items ? nextElement(items) : <></>}
    </Stack>
  );
}

export default connect(MapStateToProps)(Block);
