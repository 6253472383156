import React from "react";
import Box from "@mui/material/Box";

import End from "./End";
import Start from "./Start";
import Say from "./Say";
import Video from "./Video";
import Question from "./Question";
import If from "./If";
import Block from "./Block";
import Paragraph from "./Paragraph";

export function getChoises(blockContent, target) {
  if (blockContent) {
    return blockContent.map(function (item) {
      if (item) {
        switch (item.type) {
          case "start":
            if (item.id === target) {
              return React.createElement(Start, {
                item: item,
                target: item.next,
                blockContent: blockContent,
              });
            } else {
              return null;
            }

          case "block":
            if (item.id === target) {
              return React.createElement(Block, {
                item: item,
                target: item.blockNext,
                blockContent: blockContent,
              });
            } else {
              return null;
            }

          case "end":
            if (item.id === target) {
              return React.createElement(End, {
                item: item,
                target: item.next,
                blockContent: blockContent,
              });
            } else {
              return null;
            }

          case "say":
            if (item.id === target) {
              return React.createElement(Say, {
                item: item,
                target: item.next,
                blockContent: blockContent,
              });
            } else {
              return null;
            }

          case "video":
            if (item.id === target) {
              return React.createElement(Video, {
                item: item,
                target: item.next,
                blockContent: blockContent,
              });
            } else {
              return null;
            }

          case "paragraph":
            if (item.id === target) {
              return React.createElement(Paragraph, {
                item: item,
                target: item.next,
                blockContent: blockContent,
              });
            } else {
              return null;
            }

          case "question":
            if (item.id === target) {
              return React.createElement(Question, {
                item: item,
                target: item.next,
                blockContent: blockContent,
              });
            } else {
              return null;
            }

          case "if":
            if (item.id === target) {
              return React.createElement(If, {
                item: item,
                ifTrueTarget: item.ifTrue,
                ifFalseTarget: item.ifFalse,
                target: item.next,
                blockContent: blockContent,
              });
            } else {
              return null;
            }

          default:
            return null;
        }
      }
      return null;
    });
  } else {
    return null;
  }
}

export function nextElement(items, parent, position) {
  if (items) {
    if (parent && !position) {
      return items.map((item, key) =>
        item &&
        item.props &&
        item.props.item &&
        item.props.item.parent &&
        parent &&
        item.props.item.parent === parent &&
        !item.props.item.isAfterComplexWidget ? (
          <Box key={key}>{item ? item : <></>}</Box>
        ) : null
      );
    } else if (parent && position === "afterIf") {
      return items.map((item, key) =>
        item &&
        item.props &&
        item.props.item &&
        item.props.item.parentComplexWidget &&
        parent &&
        item.props.item.parentComplexWidget === parent ? (
          <Box key={key}>{item ? item : <></>}</Box>
        ) : null
      );
    } else {
      return items.map((item, key) => (
        <Box key={key}>{item ? item : null}</Box>
      ));
    }
  }
}
