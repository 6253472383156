import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import axios from "axios";

import {
  addNewItemAction,
  setCurrentWidget,
  editNodeAction,
  editItemAction,
  deleteItemAction,
  setBuilderDialogIsBusy,
} from "../../../../store/actions/builderAction";

import { getAuthToken } from "../../../../internal/authToken";
import * as snackbarActions from "../../../../store/actions/snackbarActions";
import { config } from "../../../../config/Constants";
import { GetItemInfo } from "../../../../internal/buildApp";

const MapStateToProps = (state) => {
  return { builder: state.builderReducer, system: state.systemReducer };
};

var say;
var item;

function SelectSay(props) {
  const dispatch = useDispatch();
  const [sayText, setSayText] = React.useState("");
  const [sayTextError, setSayTextError] = React.useState(false);
  const [sayLabel, setSayLabel] = React.useState("");
  const [sayLabelError, setSayLabelError] = React.useState(false);
  const [newLoaded, setNewLoaded] = React.useState(false);
  const [isNewItem, setisNewItem] = React.useState(true);

  useEffect(() => {
    //check if we load data for the first time or not. if we dont check, whole process renders every time
    if (!newLoaded) {
      setNewLoaded(true);
      //ٌwhen we want to edit an item, not adding new one
      if (props.edit && props.edit.editItem && props.edit.targetWidget) {
        setisNewItem(false);
        item = GetItemInfo(props.builder.app, props.edit.itemId);
        if (item) {
          say = item;
          if (item.text[0]) {
            setSayText(item.text[0]);
          }

          if (item.label) {
            setSayLabel(item.label);
          }
        }
      }
    }

    if (isNewItem && props.edit && !props.edit.editItem) {
      //if we want to create new say node
      say = {
        id: uuidv4(),
        type: "say",
        next: "",
        parentBlock: "",
        outputType: "text",
        text: [sayText],
        label: sayLabel,
        parent: "",
      };
    }
  }, [sayText, sayLabel, props.edit, props.builder.app, newLoaded, isNewItem]);

  function clearWidgetSelectStore() {
    dispatch(setCurrentWidget(""));
  }

  function closeEditItemPanel() {
    dispatch(
      editNodeAction({
        openDialog: "none",
        addNew: null,
        parentId: null,
      })
    );
    clearWidgetSelectStore();
  }

  function setSayTextFunc(e) {
    let val = e.target.value;
    if (val && val.length > 0) {
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setSayText(val);
        if (sayText && sayText.length > 0) {
          setSayTextError(false);
        }
      }
    } else {
      setSayText([]);
    }
  }

  function setSaylabelFunc(e) {
    let val = e.target.value;
    if (val && val.length > 0) {
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setSayLabel(val);
        if (sayLabel && sayLabel.length > 0) {
          setSayLabelError(false);
        }
      }
    } else {
      setSayLabel("");
    }
  }

  function addNewItem() {
    if (say) {
      say.text[0] = sayText;
      say.label = sayLabel;
    }
    if (
      say &&
      say.id &&
      say.type === "say" &&
      say.text[0] &&
      say.text[0].length > 0 &&
      say.label &&
      say.label.length > 0 &&
      props.edit &&
      say.text[0] === sayText &&
      say.label === sayLabel
    ) {
      if (isNewItem) {
        //if we want to add new item
        dispatch(
          addNewItemAction({
            item: say,
            config: props.edit,
            clickOnPlus: props.clickOnPlus,
            isSubWidget: props.isSubWidget,
            subType: props.subType,
            subParentType: props.subParentType,
            isAfterComplexWidget: props.isAfterComplexWidget,
            complexWidgetType: props.complexWidgetType,
            complexWidgetNext: props.complexWidgetNext,
            parentNext: props.parentNext,
            parentBlock: props.parentBlock,
          })
        );

        dispatchBuilderDialogIsBusyAction(true);
      } else {
        //if we want to edit an item
        dispatch(
          editItemAction({
            item: say,
          })
        );
        dispatchBuilderDialogIsBusyAction(true);
      }
      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    } else {
      if ((say && !say.text[0]) || say.text[0].length === 0) {
        setSayTextError(true);
      }

      if ((say && !say.label) || say.label.length === 0) {
        setSayLabelError(true);
      }
    }
  }

  function dispatchBuilderDialogIsBusyAction(value) {
    dispatch(setBuilderDialogIsBusy(value));
  }

  function deleteItem() {
    if (item && item.id) {
      dispatch(
        deleteItemAction({
          item: item,
        })
      );
      dispatchBuilderDialogIsBusyAction(true);

      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    }
  }

  function saveApptoServer(app) {
    if (app && app.name && getAuthToken().token) {
      const rawData = {
        token: getAuthToken().token,
        appUsername: app.name,
        app: JSON.stringify(app),
      };
      axios({
        method: "post",
        url: config.url.REPLY_SERVER + "/api/v1/apps/saveapp",
        data: rawData,
        timeout: 15000,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (res.data && res.data.response.status === 1) {
            dispatchBuilderDialogIsBusyAction(false);
            closeEditItemPanel();
          } else {
            if (res.data.response.errors) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
              dispatchBuilderDialogIsBusyAction(false);
            }
          }
        })
        .catch(function (res) {
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Action not saved",
              severity: "error",
              autoHide: true,
            })
          );
          dispatchBuilderDialogIsBusyAction(false);
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Bad request",
          severity: "error",
          autoHide: true,
        })
      );
      dispatchBuilderDialogIsBusyAction(false);
    }
  }

  return (
    <Stack
      spacing={3}
      disabled={props.builder.builderDialogIsBusy ? true : false}>
      <Box>
        <TextField
          id='outlined-basic'
          error={sayTextError}
          label='Text'
          variant='outlined'
          onChange={setSayTextFunc}
          value={sayText}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 200 }}
        />
      </Box>
      <Box>
        <TextField
          id='outlined-basic'
          error={sayLabelError}
          label='Label'
          variant='outlined'
          onChange={setSaylabelFunc}
          value={sayLabel}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 30 }}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Button
          variant='contained'
          fullWidth
          onClick={() => addNewItem()}
          disabled={
            props.builder.builderDialogIsBusy
              ? true
              : sayText[0] && sayLabel
              ? false
              : true
          }>
          Save
        </Button>
      </Box>

      {!isNewItem ? (
        <Box sx={{ width: "100%" }}>
          <Button
            variant='contained'
            color='error'
            fullWidth
            onClick={() => deleteItem()}
            disabled={props.builder.builderDialogIsBusy ? true : false}>
            Remove
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
}
export default connect(MapStateToProps)(SelectSay);
