import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowForward from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBack from "@mui/icons-material/ArrowBackIosRounded";

import {
  editNodeAction,
  setCurrentWidget,
} from "../../../store/actions/builderAction";

const MapStateToProps = (state) => {
  return {
    wsReducer: state.websocketReducer,
    system: state.systemReducer,
  };
};

function CustomAppBar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  function closeEditItemPanel() {
    dispatch(
      editNodeAction({
        openDialog: "none",
        addNew: null,
        parentId: null,
      })
    );

    dispatch(setCurrentWidget(""));
  }

  function goBack() {
    console.log();
    if (location.pathname !== "/") {
      navigate(-1);
      closeEditItemPanel();
    }
  }

  return (
    <>
      <AppBar sx={{ backgroundColor: "primary" }}>
        <Toolbar>
          <Typography variant='h6' component='div'>
            <Stack direction='row'>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {location.pathname === "/" ? (
                  <img
                    src={process.env.PUBLIC_URL + "/icons/sheldon.svg"}
                    alt='sheldon logo'
                    width='25px'
                    height='25px'
                  />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      margin: 0,
                    }}>
                    {props.system.themeDir === "rtl" ? (
                      <ArrowForward
                        style={{ fill: "white" }}
                        onClick={() => goBack()}
                      />
                    ) : (
                      <ArrowBack
                        style={{ fill: "white" }}
                        onClick={() => goBack()}
                      />
                    )}
                  </Box>
                )}
              </Box>
              <Box>&nbsp;</Box>
              <Box>ELECTERA</Box>
            </Stack>
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}

export default connect(MapStateToProps)(CustomAppBar);
