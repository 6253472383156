import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

import { getAuthToken, setAuthToken } from "../../../internal/authToken";
import { saveThemeMode } from "../../../internal/saveTheme";
import { setThemeModeAction } from "../../../store/actions/systemAction";
import LanguageSelect from "../../UI/Dialogs/LanguageDialog";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function Settings(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [auth, setAuth] = React.useState(null);
  const [fetchingUser, setFetchingUser] = React.useState(true);
  const [userPhone, setUserPhone] = React.useState("");
  const { t } = useTranslation();

  const handleDarkModeChange = (event) => {
    var darkMode;
    if (event.target.checked) {
      darkMode = "dark";
    } else {
      darkMode = "light";
    }

    dispatch(setThemeModeAction(darkMode));
    saveThemeMode({ themeMode: darkMode });
  };

  useEffect(() => {
    setAuth(getAuthToken());

    function fetchUser() {
      if (getAuthToken() && getAuthToken().token) {
        const rawData = { token: getAuthToken().token };
        setFetchingUser(true);
        axios({
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: rawData,
          url: config.url.ACCOUNTS_SERVER + "/api/v1/auth/getuserwithtoken",
        })
          .then(function (res) {
            if (res.data && res.data.response) {
              if (res.data.response.status === 1) {
                if (res.data.response.userMobile) {
                  setUserPhone(res.data.response.userMobile);
                }
                setFetchingUser(false);
              } else {
                setFetchingUser(false);
              }
            }
          })
          .catch(function (res) {
            setFetchingUser(false);
          });
      }
    }

    if (getAuthToken() && getAuthToken().token) {
      fetchUser();
    }
  }, []);

  function goToLogin() {
    navigate("/settings/login");
  }

  function handleLogOut() {
    setAuth("");
    setAuthToken(null);
    navigate("/settings/");
  }

  return (
    <Grid
      item
      xs={12}
      sx={{ backgroundColor: "background.default", minWidth: "100%" }}>
      <Box
        sx={{
          marginTop: "30px",
          minWidth: "50%",
          display: "inline-block",
        }}>
        {auth && auth.token ? (
          <Box sx={{ textAlign: "center" }}>
            {fetchingUser ? (
              <CircularProgress />
            ) : userPhone !== "" ? (
              <Box>
                <h1>{userPhone}</h1>
              </Box>
            ) : (
              <Box>Error when fetching user</Box>
            )}
          </Box>
        ) : (
          <Stack direction='row' spacing={2}>
            <Button
              variant='contained'
              fullwidth='true'
              onClick={() => goToLogin()}
              sx={{
                minWidth: "100%",
              }}>
              {t("Settings login button")}
            </Button>
          </Stack>
        )}
        <>
          <Box sx={{ width: "100%" }}>
            <br />
            <Stack direction='row' spacing={2}>
              <Box
                sx={{
                  textAlign: "start",
                  display: "inline-block",
                  width: "100%",
                }}>
                <Box sx={{ width: "100%" }}>
                  {t("SETTINGS THEME DARKMODE TITLE")}
                </Box>
              </Box>
              <Box></Box>
              <Box
                sx={{
                  textAlign: "end",
                  display: "inline-block",
                  width: "100%",
                }}>
                <Switch
                  checked={props.system.themeMode === "light" ? false : true}
                  onChange={handleDarkModeChange}
                />
              </Box>
            </Stack>

            <Box sx={{ marginTop: "30px" }}>
              <Box
                sx={{
                  textAlign: "start",
                  display: "inline-block",
                  width: "100%",
                }}>
                <Typography component='div'>
                  {t("SETTINGS LANGUAGE SELECT TITLE")}
                </Typography>
              </Box>
              <LanguageSelect />
            </Box>

            {auth && auth.token ? (
              <Box>
                <Box
                  style={{
                    textAlign: "start",
                    marginTop: "40px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleLogOut();
                  }}>
                  {t("SETTINGS LOGOUT TEXT")}
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </>
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(Settings);
