import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chip from "@mui/material/Chip";

import {
  addNewItemAction,
  setCurrentWidget,
  editNodeAction,
  editItemAction,
  deleteItemAction,
  setBuilderDialogIsBusy,
} from "../../../../store/actions/builderAction";

import { getAuthToken } from "../../../../internal/authToken";
import * as snackbarActions from "../../../../store/actions/snackbarActions";
import { config } from "../../../../config/Constants";
import { GetItemInfo } from "../../../../internal/buildApp";

const MapStateToProps = (state) => {
  return { builder: state.builderReducer, system: state.systemReducer };
};

var question;
var item;

function SelectQuestion(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [qText, setQText] = React.useState("");
  const [qTextError, setQTextError] = React.useState(false);
  const [qLabel, setQLabel] = React.useState("");
  const [qLabelError, setQLabelError] = React.useState(false);
  const [qReply, setQReply] = React.useState("");
  // const [qReplyError, setQReplyError] = React.useState(false);
  const [qStorage, setQStorage] = React.useState("");
  // const [qStorageError, setQStorageError] = React.useState(false);
  const [newLoaded, setNewLoaded] = React.useState(false);
  const [isNewItem, setisNewItem] = React.useState(true);

  const [replies, setReplies] = React.useState([]);

  useEffect(() => {
    //check if we load data for the first time or not. if we dont check, whole process renders every time
    if (!newLoaded) {
      setNewLoaded(true);
      //ٌwhen we want to edit an item, not adding new one
      if (props.edit && props.edit.editItem && props.edit.targetWidget) {
        setisNewItem(false);
        item = GetItemInfo(props.builder.app, props.edit.itemId);
        if (item) {
          question = item;
          if (item.text[0]) {
            setQText(item.text[0]);
          }

          if (item.label) {
            setQLabel(item.label);
          }

          if (item.quickReply && item.quickReply.length > 0) {
            setReplies(item.quickReply);
          }

          if (item.resultStorage && item.resultStorage.contextKeyToUse) {
            setQStorage(item.resultStorage.contextKeyToUse);
          }
        }
      }
    }

    if (isNewItem && props.edit && !props.edit.editItem) {
      //if we want to create new say node
      question = {
        id: uuidv4(),
        type: "question",
        next: "",
        parentBlock: "",
        outputType: "text",
        text: [qText],
        label: qLabel,
        quickReply: replies,
        resultStorage: { contextKeyToUse: qStorage },
        parent: "",
      };
    }
  }, [
    qText,
    qLabel,
    props.edit,
    props.builder.app,
    newLoaded,
    isNewItem,
    replies,
    qStorage,
  ]);

  function dispatchBuilderDialogIsBusyAction(value) {
    dispatch(setBuilderDialogIsBusy(value));
  }

  function clearWidgetSelectStore() {
    dispatch(setCurrentWidget(""));
  }

  function closeEditItemPanel() {
    dispatch(
      editNodeAction({
        openDialog: "none",
        addNew: null,
        parentId: null,
      })
    );
    clearWidgetSelectStore();
  }

  function handleQuestionTextChange(e) {
    let val = e.target.value;
    const res = /[`*+%$[\]{}\\|<>~]/.test(val);
    if (!res || val === "") {
      setQText(val);
    }
  }

  function handleQuestionLabelChange(e) {
    let val = e.target.value;
    const res = /[`*+%$[\]{}\\|<>~]/.test(val);
    if (!res || val === "") {
      setQLabel(val);
    }
  }

  function handleQuestionReplyChange(e) {
    if (qReply.length < 45) {
      let val = e.target.value;
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setQReply(val);
      }
    }
  }

  function handleQuestionStorageChange(e) {
    let val = e.target.value;
    const res = /^[a-z0-9_.]+$/.test(val);
    if (!!res || val === "") {
      setQStorage(val);
    }
  }

  function addNewItem() {
    if (question) {
      question.text[0] = qText;
      question.label = qLabel;
      question.quickReply = replies;
      question.resultStorage = { contextKeyToUse: qStorage };
    }
    if (
      question &&
      question.id &&
      question.type === "question" &&
      question.text[0] &&
      question.text[0].length > 0 &&
      question.label &&
      question.label.length > 0 &&
      question.resultStorage &&
      question.resultStorage.contextKeyToUse.length > 0 &&
      props.edit &&
      question.text[0] === qText &&
      question.label === qLabel
    ) {
      if (isNewItem) {
        // if we want to add new item
        dispatchBuilderDialogIsBusyAction(true);
        dispatch(
          addNewItemAction({
            item: question,
            config: props.edit,
            clickOnPlus: props.clickOnPlus,
            isSubWidget: props.isSubWidget,
            subType: props.subType,
            subParentType: props.subParentType,
            isAfterComplexWidget: props.isAfterComplexWidget,
            complexWidgetType: props.complexWidgetType,
            complexWidgetNext: props.complexWidgetNext,
            parentNext: props.parentNext,
            parentBlock: props.parentBlock,
          })
        );
      } else {
        //if we want to edit an item
        dispatchBuilderDialogIsBusyAction(true);
        dispatch(
          editItemAction({
            item: question,
          })
        );
      }
      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    } else {
      if ((question && !question.text[0]) || question.text[0].length === 0) {
        setQTextError(true);
      }

      if ((question && !question.label) || question.label.length === 0) {
        setQLabelError(true);
      }
    }
  }

  function deleteItem() {
    if (item && item.id) {
      dispatchBuilderDialogIsBusyAction(true);
      dispatch(
        deleteItemAction({
          item: item,
        })
      );

      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    }
  }

  function saveApptoServer(app) {
    if (app && app.name && getAuthToken().token) {
      const rawData = {
        token: getAuthToken().token,
        appUsername: app.name,
        app: JSON.stringify(app),
      };
      axios({
        method: "post",
        url: config.url.REPLY_SERVER + "/api/v1/apps/saveapp",
        data: rawData,
        timeout: 15000,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (res.data && res.data.response.status === 1) {
            dispatchBuilderDialogIsBusyAction(false);
            closeEditItemPanel();
          } else {
            if (res.data.response.errors) {
              dispatchBuilderDialogIsBusyAction(false);
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          dispatchBuilderDialogIsBusyAction(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Action not saved",
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatchBuilderDialogIsBusyAction(false);
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Bad request",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  const handleReplyDelete = (chipToDelete) => () => {
    if (chipToDelete) {
      setReplies((chips) => chips.filter((chip) => chip !== chipToDelete));
    }
  };

  function handleReplyAdd(chipToAdd) {
    if (chipToAdd) {
      setReplies((current) => [...current, chipToAdd]);
      setQReply("");
    }
  }

  return (
    <Stack spacing={3}>
      <Box>
        <TextField
          id='outlined-basic'
          error={qTextError}
          label={t("BUILDER WIDGETS SELECT QUESTION QUESTION LABEL")}
          variant='outlined'
          onChange={handleQuestionTextChange}
          value={qText}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 200 }}
        />
      </Box>
      <Box>
        <TextField
          id='outlined-basic'
          error={qLabelError}
          label={t("BUILDER WIDGETS SELECT QUESTION LABEL LABEL")}
          variant='outlined'
          onChange={handleQuestionLabelChange}
          value={qLabel}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 30 }}
        />
      </Box>

      <Box>
        <TextField
          id='outlined-basic'
          disabled={props.builder.builderDialogIsBusy ? true : false}
          label={t("BUILDER WIDGETS SELECT QUESTION REPLIES LABEL")}
          variant='outlined'
          onChange={handleQuestionReplyChange}
          value={qReply}
          fullWidth
          InputProps={{
            maxLength: 30,
            endAdornment: (
              <InputAdornment
                position='start'
                sx={{ cursor: "pointer" }}
                onClick={() => handleReplyAdd(qReply)}>
                <AddCircleOutlineIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        sx={{
          marginTop: replies.slice(-1)[0] ? "5px !important" : "0px !important",
        }}>
        {replies.map((data) => {
          let icon;
          return (
            <Chip
              key={data}
              icon={icon}
              label={data}
              disabled={props.builder.builderDialogIsBusy ? true : false}
              onDelete={handleReplyDelete(data)}
              sx={{ margin: "3px" }}
            />
          );
        })}
      </Box>

      <Box>
        <TextField
          id='outlined-basic'
          // error={qStorageError}
          label={t("BUILDER WIDGETS SELECT QUESTION RESULTSTORAGE LABEL")}
          variant='outlined'
          onChange={handleQuestionStorageChange}
          value={qStorage}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 20 }}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Button
          variant='contained'
          fullWidth
          onClick={() => addNewItem()}
          disabled={
            qText && qLabel && qStorage
              ? props.builder.builderDialogIsBusy
                ? true
                : false
              : true
          }>
          Save
        </Button>
      </Box>

      {!isNewItem ? (
        <Box sx={{ width: "100%" }}>
          <Button
            variant='contained'
            color='error'
            fullWidth
            onClick={() => deleteItem()}
            disabled={props.builder.builderDialogIsBusy ? true : false}>
            Remove
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
}
export default connect(MapStateToProps)(SelectQuestion);
