import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import axios from "axios";

import {
  addNewItemAction,
  setCurrentWidget,
  editNodeAction,
  editItemAction,
  deleteItemAction,
  setBuilderDialogIsBusy,
} from "../../../../store/actions/builderAction";

import { getAuthToken } from "../../../../internal/authToken";
import * as snackbarActions from "../../../../store/actions/snackbarActions";
import { config } from "../../../../config/Constants";
import { GetItemInfo } from "../../../../internal/buildApp";

const MapStateToProps = (state) => {
  return { builder: state.builderReducer, system: state.systemReducer };
};

var paragraph;
var item;

function SelectParagraph(props) {
  const dispatch = useDispatch();
  const [paraText, setParaText] = React.useState("");
  const [paraTitle, setParaTitle] = React.useState("");
  const [paraTextError, setParaTextError] = React.useState(false);
  const [paraTitleError, setParaTitleError] = React.useState(false);
  const [paraLabel, setParaLabel] = React.useState("");
  const [paraLabelError, setParaLabelError] = React.useState(false);
  const [newLoaded, setNewLoaded] = React.useState(false);
  const [isNewItem, setisNewItem] = React.useState(true);

  useEffect(() => {
    //check if we load data for the first time or not. if we dont check, whole process renders every time
    if (!newLoaded) {
      setNewLoaded(true);
      //ٌwhen we want to edit an item, not adding new one
      if (props.edit && props.edit.editItem && props.edit.targetWidget) {
        setisNewItem(false);
        item = GetItemInfo(props.builder.app, props.edit.itemId);
        if (item) {
          paragraph = item;
          if (item.text[0]) {
            setParaText(item.text[0]);
          }

          if (item.title) {
            setParaTitle(item.title);
          }

          if (item.label) {
            setParaLabel(item.label);
          }
        }
      }
    }

    if (isNewItem && props.edit && !props.edit.editItem) {
      //if we want to create new paragraph node
      paragraph = {
        id: uuidv4(),
        type: "paragraph",
        next: "",
        parentBlock: "",
        outputType: "text",
        text: [paraText],
        label: paraLabel,
        title: paraTitle,
        parent: "",
      };
    }
  }, [
    paraText,
    paraTitle,
    paraLabel,
    props.edit,
    props.builder.app,
    newLoaded,
    isNewItem,
  ]);

  function clearWidgetSelectStore() {
    dispatch(setCurrentWidget(""));
  }

  function closeEditItemPanel() {
    dispatch(
      editNodeAction({
        openDialog: "none",
        addNew: null,
        parentId: null,
      })
    );
    clearWidgetSelectStore();
  }

  function setParaTextFunc(e) {
    let val = e.target.value;
    if (val && val.length > 0) {
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setParaText(val);
        if (paraText && paraText.length > 0) {
          setParaTextError(false);
        }
      }
    } else {
      setParaText([]);
    }
  }

  function setParaTitleFunc(e) {
    let val = e.target.value;
    if (val && val.length > 0) {
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setParaTitle(val);
        if (paraTitle && paraTitle.length > 0) {
          setParaTitleError(false);
        }
      }
    } else {
      setParaTitle("");
    }
  }

  function setParaLabelFunc(e) {
    let val = e.target.value;
    if (val && val.length > 0) {
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setParaLabel(val);
        if (paraLabel && paraLabel.length > 0) {
          setParaLabelError(false);
        }
      }
    } else {
      setParaLabel("");
    }
  }

  function addNewItem() {
    if (paragraph) {
      paragraph.text[0] = paraText;
      paragraph.label = paraLabel;
      paragraph.title = paraTitle;
    }
    if (
      paragraph &&
      paragraph.id &&
      paragraph.type === "paragraph" &&
      paragraph.text[0] &&
      paragraph.text[0].length > 0 &&
      paragraph.label &&
      paragraph.label.length > 0 &&
      paragraph.title &&
      paragraph.title.length > 0 &&
      props.edit &&
      paragraph.text[0] === paraText &&
      paragraph.label === paraLabel &&
      paragraph.title === paraTitle
    ) {
      if (isNewItem) {
        //if we want to add new item
        dispatch(
          addNewItemAction({
            item: paragraph,
            config: props.edit,
            clickOnPlus: props.clickOnPlus,
            isSubWidget: props.isSubWidget,
            subType: props.subType,
            subParentType: props.subParentType,
            isAfterComplexWidget: props.isAfterComplexWidget,
            complexWidgetType: props.complexWidgetType,
            complexWidgetNext: props.complexWidgetNext,
            parentNext: props.parentNext,
            parentBlock: props.parentBlock,
          })
        );

        dispatchBuilderDialogIsBusyAction(true);
      } else {
        //if we want to edit an item
        dispatch(
          editItemAction({
            item: paragraph,
          })
        );
        dispatchBuilderDialogIsBusyAction(true);
      }
      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    } else {
      if ((paragraph && !paragraph.text[0]) || paragraph.text[0].length === 0) {
        setParaTextError(true);
      }

      if ((paragraph && !paragraph.label) || paragraph.label.length === 0) {
        setParaLabelError(true);
      }

      if ((paragraph && !paragraph.title) || paragraph.title.length === 0) {
        setParaTitleError(true);
      }
    }
  }

  function dispatchBuilderDialogIsBusyAction(value) {
    dispatch(setBuilderDialogIsBusy(value));
  }

  function deleteItem() {
    if (item && item.id) {
      dispatch(
        deleteItemAction({
          item: item,
        })
      );
      dispatchBuilderDialogIsBusyAction(true);

      if (props.builder.app) {
        saveApptoServer(props.builder.app);
      }
    }
  }

  function saveApptoServer(app) {
    if (app && app.name && getAuthToken().token) {
      const rawData = {
        token: getAuthToken().token,
        appUsername: app.name,
        app: JSON.stringify(app),
      };
      axios({
        method: "post",
        url: config.url.REPLY_SERVER + "/api/v1/apps/saveapp",
        data: rawData,
        timeout: 15000,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (res.data && res.data.response.status === 1) {
            dispatchBuilderDialogIsBusyAction(false);
            closeEditItemPanel();
          } else {
            if (res.data.response.errors) {
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
              dispatchBuilderDialogIsBusyAction(false);
            }
          }
        })
        .catch(function (res) {
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Action not saved",
              severity: "error",
              autoHide: true,
            })
          );
          dispatchBuilderDialogIsBusyAction(false);
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Bad request",
          severity: "error",
          autoHide: true,
        })
      );
      dispatchBuilderDialogIsBusyAction(false);
    }
  }

  return (
    <Stack
      spacing={3}
      disabled={props.builder.builderDialogIsBusy ? true : false}>
      <Box>
        <TextField
          id='outlined-basic'
          error={paraTitleError}
          label='Title'
          variant='outlined'
          onChange={setParaTitleFunc}
          value={paraTitle}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 50 }}
        />
      </Box>
      <Box>
        <TextField
          id='outlined-basic'
          error={paraTextError}
          label='Text'
          variant='outlined'
          onChange={setParaTextFunc}
          value={paraText}
          fullWidth
          multiline
          rows={4}
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 1000 }}
        />
      </Box>
      <Box>
        <TextField
          id='outlined-basic'
          error={paraLabelError}
          label='Label'
          variant='outlined'
          onChange={setParaLabelFunc}
          value={paraLabel}
          fullWidth
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 30 }}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Button
          variant='contained'
          fullWidth
          onClick={() => addNewItem()}
          disabled={
            props.builder.builderDialogIsBusy
              ? true
              : paraText[0] && paraLabel && paraTitle
              ? false
              : true
          }>
          Save
        </Button>
      </Box>

      {!isNewItem ? (
        <Box sx={{ width: "100%" }}>
          <Button
            variant='contained'
            color='error'
            fullWidth
            onClick={() => deleteItem()}
            disabled={props.builder.builderDialogIsBusy ? true : false}>
            Remove
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
}
export default connect(MapStateToProps)(SelectParagraph);
