export const initAppAction = (value) => ({
  type: "INIT_APP_ACTION",
  payload: value,
});

export const addNewItemAction = (value) => ({
  type: "ADD_NEW_ITEM_ACTION",
  payload: value,
});

export const setCurrentBlock = (value) => ({
  type: "CURRENT_BLOCK",
  payload: value,
});

export const editNodeAction = (value) => ({
  type: "EDIT_NODE",
  payload: value,
});

export const editItemAction = (value) => ({
  type: "EDIT_ITEM_ACTION",
  payload: value,
});

export const deleteItemAction = (value) => ({
  type: "DELETE_ITEM_ACTION",
  payload: value,
});

export const setSelectBlockWidget = (value) => ({
  type: "SELECT_BLOCK_WIDGET",
  payload: value,
});

export const setCurrentWidget = (value) => ({
  type: "SET_CURRENT_WIDGET",
  payload: value,
});

export const setBuilderDialogIsBusy = (value) => ({
  type: "SET_BUILDER_DIALOG_IS_BUSY",
  payload: value,
});

export const setSaveAppAction = (value) => ({
  type: "SET_SAVE_APP",
  payload: value,
});
