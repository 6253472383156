import * as React from "react";
import Box from "@mui/material/Box";

function Home(props) {
  return (
    <Box sx={{ marginTop: "30px", backgroundColor: "background.default" }}>
      Home
    </Box>
  );
}

export default Home;
