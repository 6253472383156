const newMessageReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "MESSAGE_NEW_LOCAL":
      return Object.assign({}, state, {
        messages: state.messages.concat(payload),
      });

    case "MESSAGE_NEW_SENT_LIST":
      return Object.assign({}, state, {
        sent_messages_list: state.sent_messages_list.concat(payload),
      });

    case "MESSAGE_NEW_RECEIVED":
      return Object.assign({}, state, {
        received_messages_list: state.received_messages_list.concat(payload),
      });

    case "MESSAGE_SEEN_RECEIVED":
      return { ...state, seen: payload };

    case "ROOM_NEW_ID":
      return { ...state, id: payload };

    default:
      return state;
  }
};

export default newMessageReducer;
