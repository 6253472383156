import * as React from "react";
import Grid from "@mui/material/Grid";

import Canvas from "../Canvas/Canvas";

function Builder(props) {
  return (
    <Grid
      container
      justify='center'
      spacing={0}
      direction='column'
      alignItems='center'
      sx={{ backgroundColor: "background.default" }}
      justifyContent='center'>
      <Grid justify='center' item>
        <Canvas />
      </Grid>
    </Grid>
  );
}

export default Builder;
