import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import * as snackbarActions from "../../../store/actions/snackbarActions";
import { getAuthToken } from "../../../internal/authToken";
import { config } from "../../../config/Constants";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function NewApp(props) {
  const { t } = useTranslation();
  const [appName, setAppName] = React.useState("");
  const [appUsername, setAppUsername] = React.useState("");
  const [appColor, setAppColor] = React.useState("");
  const [appDesc, setAppDesc] = React.useState("");
  const [saveClicked, setSaveClicked] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleAppNameChange(event) {
    let val = event.target.value;
    const res = /[`!@«»#$%^&*_+\-=[\]{};':"\\|,.<>?~]/.test(val);
    if (!res || val === "") {
      setAppName(val);
    }
  }

  function handleAppUsernameChange(event) {
    let val = event.target.value;
    const res = /^[a-z0-9_.]+$/.test(val);
    if (!!res || val === "") {
      setAppUsername(val);
    }
  }

  function handleAppColorChange(event) {
    let val = event.target.value;
    const res = /^[a-z0-9_#.]+$/.test(val);
    if (res || val === "") {
      setAppColor(val);
    }
  }

  function handleAppDescChange(event) {
    let val = event.target.value;
    const res = /[`^&*+=[\]{};':"\\|,.<>?~]/.test(val);
    if (!res || val === "") {
      setAppDesc(val);
    }
  }

  function saveApp(event) {
    if (event) {
      event.preventDefault();
    }

    if (appName && appUsername && appColor) {
      setSaveClicked(true);
      const rawData = {
        token: getAuthToken().token,
        appname: appName,
        appusername: appUsername,
        appcolor: appColor,
        appdesc: appDesc,
      };

      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: rawData,
        url: config.url.REPLY_SERVER + "/api/v1/apps/createapp",
      })
        .then(function (res) {
          if (res.data && res.data.response) {
            if (res.data.response.status === 1 && res.data.response.appId) {
              navigate("/dashboard/builder/" + res.data.response.appId + "/");
            } else {
              setSaveClicked(false);
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          setSaveClicked(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Error creating App(130)",
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: t("DASHBOARD NEW APP ERROR INCOMPLETE FORM"),
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Grid
      item
      xs={12}
      sx={{ backgroundColor: "background.default", minWidth: "100%" }}>
      <Box
        sx={{
          marginTop: "30px",
          minWidth: "50%",
          display: "inline-block",
        }}>
        <Box
          sx={{
            minWidth: "100%",
            textAlign: "start",
          }}>
          <Typography variant='h4' gutterBottom component='div'>
            {t("DASHBOARD NEW APP TITLE")}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "-20px",
            minWidth: "100%",
            textAlign: "start",
          }}>
          <Typography variant='subtitle1' gutterBottom component='div'>
            {t("DASHBOARD NEW APP SUB TITLE")}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <form autoComplete='off'>
            <Box>
              <TextField
                id='outlined-basic'
                InputLabelProps={{ shrink: false }}
                inputProps={{
                  maxLength: 30,
                  style: {
                    textAlign: "start",
                    direction: "ltr",
                    flip: "false",
                  },
                }}
                type='text'
                value={appName}
                required
                margin='normal'
                placeholder={t("DASHBOARD NEW APP INPUTS APP NAME")}
                fullWidth={true}
                variant='outlined'
                onChange={handleAppNameChange}
              />
            </Box>

            <Box>
              <TextField
                id='outlined-basic'
                InputLabelProps={{ shrink: false }}
                inputProps={{
                  maxLength: 20,
                  style: {
                    textAlign: "start",
                    direction: "ltr",
                    flip: "false",
                  },
                }}
                type='text'
                value={appUsername}
                required
                margin='normal'
                placeholder={t("DASHBOARD NEW APP INPUTS APP USERNAME")}
                fullWidth={true}
                variant='outlined'
                onChange={handleAppUsernameChange}
              />
            </Box>

            <Box>
              <TextField
                id='outlined-basic'
                InputLabelProps={{ shrink: false }}
                inputProps={{
                  maxLength: 7,
                  style: {
                    textAlign: "start",
                    direction: "ltr",
                    flip: "false",
                  },
                }}
                type='text'
                value={appColor}
                required
                margin='normal'
                placeholder={t("DASHBOARD NEW APP INPUTS APP COLOR")}
                fullWidth={true}
                variant='outlined'
                onChange={handleAppColorChange}
              />
            </Box>

            <Box>
              <TextField
                id='outlined-basic'
                InputLabelProps={{ shrink: false }}
                inputProps={{
                  maxLength: 100,
                  style: {
                    textAlign: "start",
                    direction: "ltr",
                    flip: "false",
                  },
                }}
                type='text'
                value={appDesc}
                required
                margin='normal'
                placeholder={t("DASHBOARD NEW APP INPUTS APP DESCRIPTION")}
                fullWidth={true}
                variant='outlined'
                multiline
                rows={2}
                maxRows={2}
                sx={{ "& .MuiInputBase-root": { height: "" } }}
                onChange={handleAppDescChange}
              />
            </Box>

            <Box sx={{ marginTop: "15px" }}>
              <Button
                variant='contained'
                fullwidth='true'
                disabled={
                  appName &&
                  appUsername &&
                  appColor &&
                  appColor.length === 7 &&
                  saveClicked === false &&
                  appColor.substring(0, 1) === "#"
                    ? false
                    : true
                }
                onClick={() => saveApp()}
                sx={{
                  minWidth: "100%",
                }}>
                {t("DASHBOARD NEW APP SAVE BUTTON")}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Grid>
  );
}

export default connect(MapStateToProps)(NewApp);
