export function BuildApp(app, newItem) {
  return newItem;
}

//code for searching an item and fetching it to edit
export function GetItemInfo(app, targetItem) {
  var i = 0;
  if (app && app.structure && app.structure.length > 0 && targetItem) {
    for (let root of app.structure) {
      if (i !== -1) {
        var itemIndex = app.structure.indexOf(root);
        if (root && app.structure[itemIndex]) {
          if (root.type === "block") {
            //search inside each block
            var j = 0;
            if (root.blockItems && root.blockItems.length > 0 && targetItem) {
              for (let blockRoot of root.blockItems) {
                if (j !== -1) {
                  var blockItemIndex = root.blockItems.indexOf(blockRoot);
                  if (blockRoot && root.blockItems[blockItemIndex]) {
                    if (blockRoot.id === targetItem) {
                      j = -1;
                      i = -1;
                      return blockRoot;
                    }
                  }
                }
                j++;
              }
            }
          } else {
            //search main root
            if (root.id === targetItem) {
              i = -1;
              return root;
            }
          }
        }
      }
      i++;
    }
  }
}

export function EditItem(app, editingItem) {
  var i = 0;
  if (
    app &&
    app.structure &&
    app.structure.length > 0 &&
    editingItem &&
    editingItem.item &&
    editingItem.item.id
  ) {
    iloop: for (let root of app.structure) {
      var itemIndex = app.structure.indexOf(root);
      if (root && app.structure[itemIndex] && root.id === editingItem.item.id) {
        //here we checking the items of root
        if (app.structure.find((item) => item.id === editingItem.item.id)) {
          app.structure[i] = editingItem.item;
          i = -1;
          break;
        }
      } else if (root.type === "block") {
        //here we check items inside block
        var j = 0;
        var blockItems = root.blockItems;
        if (blockItems.length > 0) {
          for (let blockItem of blockItems) {
            if (
              blockItem &&
              app.structure[itemIndex] &&
              blockItems[blockItems.indexOf(blockItem)]
            ) {
              if (blockItems.find((item) => item.id === editingItem.item.id)) {
                if (
                  app.structure[itemIndex].blockItems[j].id ===
                  editingItem.item.id
                ) {
                  app.structure[itemIndex].blockItems[j] = editingItem.item;
                  i = j = -1;
                  break iloop;
                }
              }
            }
            j++;
          }
        }
      }
      i++;
    }
  }

  return app;
}

export function AddNewItem(app, newItem) {
  var i = 0;
  if (
    app &&
    app.structure &&
    app.structure.length > 0 &&
    newItem &&
    newItem.item &&
    newItem.config
  ) {
    var appendingPoint;
    var rootAppendingPoint;
    var parentNext = newItem.parentNext;

    for (let root of app.structure) {
      var itemIndex = app.structure.indexOf(root);

      //adding items to the root(where just blocks exist)
      if (
        root &&
        root.id === newItem.config.parentId &&
        app.structure[itemIndex] &&
        (root.type === "start" || root.type === "block")
      ) {
        //if we click on + icon of a block or itself
        if (root.type === "block") {
          //when we clicked on block + icon
          if (newItem.clickOnPlus) {
            //when we add new block and we want to change all pointers inside all other blocks to it's id
            //but when we click on + icon, blocks after current block will search for pointer
            //so here we search just current block, and we have to search separately other blocks inside them
            if (root.blockItems.length > 0) {
              for (let blockItem of root.blockItems) {
                if (blockItem.next === newItem.parentBlockNext) {
                  root.blockItems[root.blockItems.indexOf(blockItem)].next =
                    newItem.item.id;
                }

                if (blockItem.ifTrue === newItem.parentBlockNext) {
                  root.blockItems[root.blockItems.indexOf(blockItem)].ifTrue =
                    newItem.item.id;
                }

                if (blockItem.ifFalse === newItem.parentBlockNext) {
                  root.blockItems[root.blockItems.indexOf(blockItem)].ifFalse =
                    newItem.item.id;
                }
              }
            }

            rootAppendingPoint = i;
          } else {
            //when we clicked on block itself
            if (app.structure[itemIndex].blockItems.length === 0) {
              newItem.item.next = app.structure[itemIndex].blockNext;
              app.structure[itemIndex].next = newItem.item.id;
              newItem.item.parentBlock = app.structure[itemIndex].id;
              app.structure[itemIndex].blockItems.push(newItem.item);
            }
          }
        } else if (root.type === "start") {
          //if we click on + icon of a start
          newItem.item.blockNext = root.next;
          newItem.item.next = "";
          newItem.item.parentBlock = newItem.item.id;
          app.structure[itemIndex].next = newItem.item.id;
          app.structure.splice(i + 1, 0, newItem.item);
        }
      } else if (
        //here we working inside the blocks
        root.type === "block" &&
        newItem.parentNext &&
        root.id !== newItem.config.parentId
      ) {
        //adding items inside blocks
        var blockItems = root.blockItems;
        if (blockItems.length > 0) {
          var j = 0;
          for (let blockItem of blockItems) {
            if (
              blockItem &&
              app.structure[itemIndex] &&
              blockItems[blockItems.indexOf(blockItem)] &&
              newItem.parentNext &&
              j !== -1
            ) {
              //when we add new block and we want to change all pointers inside all other blocks to it's id
              if (newItem.item.type === "block" && newItem.parentBlockNext) {
                if (blockItem.next === newItem.parentBlockNext) {
                  blockItems[blockItems.indexOf(blockItem)].next =
                    newItem.item.id;
                }

                if (blockItem.ifTrue === newItem.parentBlockNext) {
                  blockItems[blockItems.indexOf(blockItem)].ifTrue =
                    newItem.item.id;
                }

                if (blockItem.ifFalse === newItem.parentBlockNext) {
                  blockItems[blockItems.indexOf(blockItem)].ifFalse =
                    newItem.item.id;
                }
              }

              //delete isAfterComplexWidget and complexWidgetType properties for item that
              //new item is appending before it
              if (
                blockItem.isAfterComplexWidget &&
                blockItem.id === parentNext &&
                newItem.item.parentComplexWidget ===
                  blockItem.parentComplexWidget
              ) {
                delete blockItems[blockItems.indexOf(blockItem)]
                  .isAfterComplexWidget;

                delete blockItems[blockItems.indexOf(blockItem)]
                  .complexWidgetType;

                delete blockItems[blockItems.indexOf(blockItem)]
                  .parentComplexWidget;
              }

              //when we add a complex widget before a regular widget
              if (
                blockItem.id === parentNext &&
                newItem.item.type === "if" &&
                newItem.item.parentComplexWidget ===
                  blockItem.parentComplexWidget
              ) {
                blockItems[
                  blockItems.indexOf(blockItem)
                ].isAfterComplexWidget = true;

                blockItems[blockItems.indexOf(blockItem)].complexWidgetType =
                  "if";

                blockItems[blockItems.indexOf(blockItem)].parentComplexWidget =
                  newItem.item.id;
              }

              //here we alter all pointers related to adding new item
              if (
                blockItem.next === parentNext &&
                blockItem.id !== newItem.config.parentId
              ) {
                //change all items that point to newItem's parent's next to newItem's id
                if (newItem.config.isSubWidget) {
                  if (
                    newItem.item.isAfterComplexWidget &&
                    newItem.item.parentComplexWidget === blockItem.parent
                  ) {
                    blockItems[blockItems.indexOf(blockItem)].next =
                      newItem.item.id;
                  }
                } else {
                  blockItems[blockItems.indexOf(blockItem)].next =
                    newItem.item.id;
                }

                //change ifTrue and ifFalse of an empty if widget
                if (blockItem.type === "if") {
                  if (blockItem.ifTrue === parentNext) {
                    if (newItem.config.isSubWidget) {
                      if (
                        newItem.item.isAfterComplexWidget &&
                        newItem.item.parentComplexWidget === blockItem.parent
                      ) {
                        blockItems[blockItems.indexOf(blockItem)].ifTrue =
                          newItem.item.id;
                      }
                    } else {
                      blockItems[blockItems.indexOf(blockItem)].ifTrue =
                        newItem.item.id;
                    }
                  }

                  if (blockItem.ifFalse === parentNext) {
                    if (newItem.config.isSubWidget) {
                      if (
                        newItem.item.isAfterComplexWidget &&
                        newItem.item.parentComplexWidget === blockItem.parent
                      ) {
                        blockItems[blockItems.indexOf(blockItem)].ifFalse =
                          newItem.item.id;
                      }
                    } else {
                      blockItems[blockItems.indexOf(blockItem)].ifFalse =
                        newItem.item.id;
                    }
                  }
                }
              } else if (
                blockItem.id === newItem.config.parentId &&
                blockItem.parentBlock &&
                blockItem.parentBlock === newItem.config.parentBlock
              ) {
                //if we found appending point
                appendingPoint = j;
              }
            }
            j++;
            //if we reach to the end of the list
            //here we check new item belongs to current block
            if (
              j === blockItems.length &&
              blockItems[appendingPoint] &&
              blockItems[appendingPoint].parentBlock &&
              blockItems[appendingPoint].parentBlock ===
                newItem.config.parentBlock
            ) {
              if (newItem.config.isSubWidget) {
                if (newItem.config.subParentType === "if") {
                  if (newItem.config.isAfterComplexWidget) {
                    //wehen we want to add new item after a complex widget that itself is a sub widget

                    newItem.item.parent = blockItems[appendingPoint].parent;
                    newItem.item.next = parentNext;
                    newItem.item.parentBlock =
                      blockItems[appendingPoint].parentBlock;
                    blockItems[appendingPoint].next = newItem.item.id;

                    newItem.item.isSubWidget = true;
                    newItem.item.subParentType = "if";
                    newItem.item.subType = "afterIf";

                    //if we are adding new if widget, we should fill blank ifTrue and ifFalse to the widget's next
                    if (newItem.item.type === "if") {
                      newItem.item.complexWidgetType = "if";
                      newItem.item.ifTrue = parentNext;
                      newItem.item.ifFalse = parentNext;
                    }

                    newItem.item.isAfterComplexWidget = true;

                    newItem.item.parentComplexWidget =
                      blockItems[appendingPoint].id;

                    // when we are adding new item after an empty if widget
                    if (blockItems[appendingPoint].type === "if") {
                      newItem.item.complexWidgetType = "if";

                      //change ifTrue and ifFalse of an empty if widget
                      if (blockItems[appendingPoint].ifTrue === parentNext) {
                        blockItems[appendingPoint].ifTrue = newItem.item.id;
                      }

                      if (blockItems[appendingPoint].ifFalse === parentNext) {
                        blockItems[appendingPoint].ifFalse = newItem.item.id;
                      }
                    }
                  } else {
                    if (newItem.config.subType === "true") {
                      if (blockItems[appendingPoint].type === "if") {
                        //when we add first childs to then and else of if widget
                        //when if is empty
                        //add ifFalse value to first child of if
                        newItem.item.next = blockItems[appendingPoint].ifTrue;
                        // newItem.item.ifTrue = blockItems[appendingPoint].next;
                        blockItems[appendingPoint].ifTrue = newItem.item.id;

                        newItem.item.parent = blockItems[appendingPoint].id;
                        newItem.item.parentBlock =
                          blockItems[appendingPoint].parentBlock;
                      } else if (
                        blockItems[appendingPoint].parent &&
                        newItem.item.id
                      ) {
                        blockItems[appendingPoint].next = newItem.item.id;
                        newItem.item.next = parentNext;
                        newItem.item.parent = blockItems[appendingPoint].parent;
                        newItem.item.parentBlock =
                          blockItems[appendingPoint].parentBlock;
                      }

                      newItem.item.isSubWidget = true;
                      newItem.item.subParentType = "if";
                      newItem.item.subType = "true";
                    } else if (newItem.config.subType === "false") {
                      if (blockItems[appendingPoint].type === "if") {
                        //when we add first childs to then and else of if widget
                        //when if is empty
                        //add ifFalse value to first child of if
                        newItem.item.next = blockItems[appendingPoint].ifFalse;
                        // newItem.item.ifFalse = blockItems[appendingPoint].next;
                        blockItems[appendingPoint].ifFalse = newItem.item.id;
                        newItem.item.parent = blockItems[appendingPoint].id;
                        newItem.item.parentBlock =
                          blockItems[appendingPoint].parentBlock;
                      } else if (
                        blockItems[appendingPoint].parent &&
                        newItem.item.id
                      ) {
                        blockItems[appendingPoint].next = newItem.item.id;
                        newItem.item.next = parentNext;
                        newItem.item.parent = blockItems[appendingPoint].parent;
                        newItem.item.parentBlock =
                          blockItems[appendingPoint].parentBlock;
                      }

                      newItem.item.isSubWidget = true;
                      newItem.item.subParentType = "if";
                      newItem.item.subType = "false";
                    } else if (newItem.config.subType === "afterIf") {
                      if (blockItems[appendingPoint].type === "if") {
                        //when we add first childs to then and else of if widget
                        //when if is empty
                        //add ifFalse value to first child of if
                        newItem.item.next = blockItems[appendingPoint].next;

                        if (blockItems[appendingPoint].ifFalse === parentNext) {
                          blockItems[appendingPoint].ifFalse = newItem.item.id;
                        }

                        if (blockItems[appendingPoint].ifTrue === parentNext) {
                          blockItems[appendingPoint].ifTrue = newItem.item.id;
                        }

                        newItem.item.parent = blockItems[appendingPoint].id;
                        newItem.item.parentBlock =
                          blockItems[appendingPoint].parentBlock;
                      } else if (
                        blockItems[appendingPoint].parent &&
                        newItem.item.id
                      ) {
                        blockItems[appendingPoint].next = newItem.item.id;
                        newItem.item.next = parentNext;
                        newItem.item.parent = blockItems[appendingPoint].parent;
                        newItem.item.parentBlock =
                          blockItems[appendingPoint].parentBlock;
                      }

                      newItem.item.isSubWidget = true;
                      newItem.item.subParentType = "if";
                      newItem.item.subType = "afterIf";
                    }
                    if (newItem.item.type === "if") {
                      //if we are adding new if widget inside an if widget!
                      //if we are adding new if widget, we should fill blank ifTrue and ifFalse to the widget's next
                      newItem.item.complexWidgetType = "if";
                      newItem.item.ifTrue = parentNext;
                      newItem.item.ifFalse = parentNext;
                    }
                  }
                }

                app.structure[itemIndex].blockItems.splice(
                  appendingPoint + 1,
                  0,
                  newItem.item
                );
                j = -1;
                break;
              } else {
                //when newItem is not subwidget
                newItem.item.next = parentNext;
                newItem.item.parentBlock =
                  blockItems[appendingPoint].parentBlock;

                blockItems[appendingPoint].next = newItem.item.id;

                //if we are adding new if widget, we should fill blank ifTrue and ifFalse to the widget's next
                if (newItem.item.type === "if") {
                  newItem.item.complexWidgetType = "if";
                  newItem.item.ifTrue = parentNext;
                  newItem.item.ifFalse = parentNext;
                }

                //here we alter pointers inside a complex widget like if
                if (newItem.isAfterComplexWidget) {
                  newItem.item.isAfterComplexWidget = true;
                  newItem.item.parentComplexWidget =
                    blockItems[appendingPoint].id;

                  if (blockItems[appendingPoint].type === "if") {
                    newItem.item.complexWidgetType = "if";

                    //change ifTrue and ifFalse of an empty if widget
                    if (blockItems[appendingPoint].ifTrue === parentNext) {
                      blockItems[appendingPoint].ifTrue = newItem.item.id;
                    }

                    if (blockItems[appendingPoint].ifFalse === parentNext) {
                      blockItems[appendingPoint].ifFalse = newItem.item.id;
                    }
                  }
                }

                //if we are adding new item after complex widget that itself is a child of another complex widget
                //then we should mark new widget as a sub widget too
                if (blockItem.isSubWidget && newItem.item.isSubWidget) {
                  if (blockItem.subParentType === "if") {
                    newItem.item.subParentType = "if";
                  }
                  if (newItem.item.isAfterComplexWidget) {
                    newItem.item.subType = "afterIf";
                  }
                }

                app.structure[itemIndex].blockItems.splice(
                  appendingPoint + 1,
                  0,
                  newItem.item
                );
                j = -1;
                break;
              }
            }
          }
        }
      }
      i++;

      //if we reach to the end of the root list(blocks list)
      if (i === app.structure.length) {
        //here we check if new item belongs to current block to avoid change other items in other blocks
        //also we check clickOnPlus value to be sure we are adding new block by clicking parent's + icon
        if (
          app.structure[rootAppendingPoint] &&
          app.structure[rootAppendingPoint].id === newItem.config.parentId &&
          app.structure[rootAppendingPoint] &&
          newItem.clickOnPlus &&
          app.structure[rootAppendingPoint].type === "block"
        ) {
          //here in the end of main loop we add new block
          newItem.item.blockNext = app.structure[rootAppendingPoint].blockNext;
          newItem.item.next = "";
          newItem.item.parentBlock = newItem.item.id;
          app.structure[rootAppendingPoint].blockNext = newItem.item.id;
          app.structure.splice(rootAppendingPoint + 1, 0, newItem.item);
          i = -1;
          break;
        }
      }
    }
  }

  return app;
}

export function DeleteItem(app, deletingItem) {
  var i = 0;

  if (
    app &&
    app.structure &&
    app.structure.length > 0 &&
    deletingItem &&
    deletingItem.item &&
    deletingItem.item.id &&
    deletingItem.item.next &&
    i !== -1
  ) {
    var targetsNext = deletingItem.item.next;
    var targetId = deletingItem.item.id;
    var deletingPoint = []; //we use this to remember the location of items we want to delete
    var deletingParents = [];

    //we store all complex widgets id's that is subparent of our target
    //so if we delete our targat that is a complex widget, we can delete all sub wudgets based
    //on this array's info

    deletingParents.push(deletingItem.item.id);

    //changing the items of root(where we have just blocks)
    for (let root of app.structure) {
      var itemIndex = app.structure.indexOf(root);

      // if we delete first item of a block, here we change next property of that block to new item after deleting point
      if (app.structure[itemIndex].next === targetId) {
        app.structure[itemIndex].next = targetsNext;
      }

      if (
        root &&
        root.id === deletingItem.item.id &&
        app.structure[itemIndex]
      ) {
        //root items(blocks)
        if (app.structure.find((item) => item.id === deletingItem.item.id)) {
        }
      } else if (root.type === "block") {
        var j = 0;
        var blockItems = root.blockItems;
        if (blockItems.length > 0 && j !== -1) {
          for (let blockItem of blockItems) {
            if (
              blockItem &&
              app.structure[itemIndex] &&
              blockItems[blockItems.indexOf(blockItem)]
            ) {
              //here we search all subwidgets of a deleting item that they are complex widget themselves
              if (blockItem.isSubWidget && blockItem.type === "if") {
                var foundParent = deletingParents.find(
                  (parent) => blockItem.parent === parent
                );
                if (foundParent) {
                  deletingParents.push(blockItem.id);
                }
              }

              if (
                deletingItem.item.isSubWidget &&
                blockItems[blockItems.indexOf(blockItem)].parent ===
                  deletingItem.item.parent
              ) {
                //if we want to delete a complex widget's after item which that complex widgets itself
                //is a sub widget
                if (deletingItem.item.subParentType === "if") {
                  if (
                    deletingItem.item.isAfterComplexWidget &&
                    deletingItem.item.subType === "afterIf"
                  ) {
                    //if we are deleting from after if widget
                    if (
                      blockItems[blockItems.indexOf(blockItem)].id ===
                      targetsNext
                    ) {
                      blockItems[
                        blockItems.indexOf(blockItem)
                      ].isAfterComplexWidget = true;

                      blockItems[
                        blockItems.indexOf(blockItem)
                      ].parentComplexWidget =
                        deletingItem.item.parentComplexWidget;

                      blockItems[blockItems.indexOf(blockItem)].subType =
                        "afterIf";
                    }
                  }
                }
              }

              if (blockItem.next === targetId) {
                blockItems[blockItems.indexOf(blockItem)].next = targetsNext;
              }

              if (blockItem.type === "if") {
                if (blockItem.ifTrue === targetId) {
                  blockItems[blockItems.indexOf(blockItem)].ifTrue =
                    targetsNext;
                }

                if (blockItem.ifFalse === targetId) {
                  blockItems[blockItems.indexOf(blockItem)].ifFalse =
                    targetsNext;
                }
              }

              //when we are deleting an item after a complex widget like if, so we have to edit some attributes
              if (
                deletingItem.item.isAfterComplexWidget &&
                blockItems[blockItems.indexOf(blockItem)].id === targetsNext
              ) {
                if (deletingItem.item.isSubWidget) {
                  if (blockItem.isSubWidget) {
                    if (blockItem.parent === deletingItem.item.parent) {
                      blockItems[
                        blockItems.indexOf(blockItem)
                      ].isAfterComplexWidget = true;

                      blockItems[
                        blockItems.indexOf(blockItem)
                      ].parentComplexWidget =
                        deletingItem.item.parentComplexWidget;

                      blockItems[blockItems.indexOf(blockItem)].subType =
                        "afterIf";
                    }
                  }
                } else {
                  blockItems[
                    blockItems.indexOf(blockItem)
                  ].isAfterComplexWidget = true;

                  blockItems[
                    blockItems.indexOf(blockItem)
                  ].parentComplexWidget = deletingItem.item.parentComplexWidget;

                  blockItems[blockItems.indexOf(blockItem)].subType = "afterIf";
                }
              }

              //delete all sub widgets of a complex widget
              if (
                blockItem.isSubWidget &&
                deletingParents.find((parent) => blockItem.parent === parent)
              ) {
                deletingPoint.push(j);
              }

              //delete isAfterComplexWidget and complexWidgetType from an item after deleting a complex PARENT widget
              if (
                deletingItem.item.type === "if" &&
                deletingItem.item.next === blockItem.id &&
                deletingItem.item.isAfterComplexWidget !== true &&
                blockItem.isAfterComplexWidget
              ) {
                if (deletingItem.item.isSubWidget) {
                  if (deletingItem.item.id === blockItem.parentComplexWidget) {
                    delete blockItems[blockItems.indexOf(blockItem)]
                      .isAfterComplexWidget;

                    delete blockItems[blockItems.indexOf(blockItem)]
                      .complexWidgetType;

                    delete blockItems[blockItems.indexOf(blockItem)]
                      .parentComplexWidget;

                    blockItems[blockItems.indexOf(blockItem)].subType =
                      deletingItem.item.subType;
                  }
                } else {
                  delete blockItems[blockItems.indexOf(blockItem)]
                    .isAfterComplexWidget;

                  delete blockItems[blockItems.indexOf(blockItem)]
                    .complexWidgetType;

                  delete blockItems[blockItems.indexOf(blockItem)]
                    .parentComplexWidget;
                }
              }

              //here we check deleting item is belongs to current block, then add deleting point index to deletingPoint
              if (
                blockItems[blockItems.indexOf(blockItem)] &&
                blockItems[blockItems.indexOf(blockItem)].id ===
                  deletingItem.item.id &&
                blockItems[blockItems.indexOf(blockItem)].parentBlock &&
                blockItems[blockItems.indexOf(blockItem)].parentBlock ===
                  deletingItem.item.parentBlock
              ) {
                //if we found the deleting target item
                deletingPoint.push(j);
              }
            }
            j++;
            //if we reach to the end of the list
            if (j === blockItems.length) {
              for (let item of deletingPoint.slice().reverse()) {
                if (
                  blockItems[item] &&
                  blockItems[item].parentBlock &&
                  blockItems[item].parentBlock === deletingItem.item.parentBlock
                ) {
                  app.structure[itemIndex].blockItems.splice(item, 1);

                  //if we deleted last item in a block and when it was empty,
                  //then we set that blocks next to same as that blocks blockNext
                  if (root.blockItems.length === 0) {
                    app.structure[itemIndex].next =
                      app.structure[itemIndex].blockNext;
                  }
                }
              }
            }
          }
        }
      }
      i++;
    }
  }
  return app;
}

export function CheckBlock(app, itemID) {
  var res;
  if (app && app.length > 0 && itemID) {
    for (let root of app) {
      if (root.type === "block" && root.id === itemID) {
        if (root.blockItems.length > 0) {
          res = true;
        } else {
          res = false;
        }
        break;
      } else {
        res = false;
      }
    }
  }

  return res;
}
