export function isAlpha(val) {
  let ret = false;
  var str = "1234567890۱۲۳۴۵۶۷۸۹۰<>!@#$%^&*()-_=+/.;',،.";
  if (!str.includes(val.slice(-1)) || val === "") {
    ret = true;
  }
  return ret;
}

export function isAlNum(val) {
  let ret = false;
  var str = "<>!#^&*_=+/;'";
  if (!str.includes(val.slice(-1)) || val === "") {
    ret = true;
  }
  return ret;
}

export function isNumber(val) {
  let ret = false;
  var str = "1234567890";
  if (str.includes(val.slice(-1)) || val === "") {
    ret = true;
  }
  return ret;
}

export function toEnNumber(val) {
  var find = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  var replace = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  var regex;

  for (var i = 0; i < find.length; i++) {
    regex = new RegExp(find[i], "g");
    val = val.replace(regex, replace[i]);
  }
  return val;
}
