import { AddNewItem, EditItem, DeleteItem } from "../../internal/buildApp";

var newApp = [];
const builderReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "INIT_APP_ACTION":
      return { ...state, app: payload };

    case "ADD_NEW_ITEM_ACTION":
      newApp = AddNewItem(state.app, payload);
      return { ...state, app: newApp };

    case "CURRENT_BLOCK":
      return { ...state, getCurrentBlock: payload };

    case "EDIT_NODE":
      return { ...state, editNode: payload };

    case "EDIT_ITEM_ACTION":
      newApp = EditItem(state.app, payload);
      return { ...state, app: newApp };

    case "DELETE_ITEM_ACTION":
      newApp = DeleteItem(state.app, payload);
      return { ...state, app: newApp };

    case "SET_CURRENT_WIDGET":
      return { ...state, getCurrentWidget: payload };

    case "SET_SAVE_APP":
      return { ...state, getSaveApp: payload };

    case "SET_BUILDER_DIALOG_IS_BUSY":
      return { ...state, builderDialogIsBusy: payload };

    default:
      return state;
  }
};

export default builderReducer;
