import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import axios from "axios";

import {
  addNewItemAction,
  setCurrentWidget,
  editNodeAction,
  setBuilderDialogIsBusy,
} from "../../../../store/actions/builderAction";

import { getAuthToken } from "../../../../internal/authToken";
import * as snackbarActions from "../../../../store/actions/snackbarActions";
import { config } from "../../../../config/Constants";

const MapStateToProps = (state) => {
  return { builder: state.builderReducer };
};

var block;

function SelectBlock(props) {
  const dispatch = useDispatch();

  const [blockLabel, setBlockLabel] = React.useState("");
  const [blockLabelError, setBlockLabelError] = React.useState(false);

  useEffect(() => {
    block = {
      id: uuidv4(),
      type: "block",
      next: "",
      blockNext: "",
      parentBlock: "",
      label: blockLabel,
      blockItems: [],
    };
  }, [blockLabel]);

  function dispatchBuilderDialogIsBusyAction(value) {
    dispatch(setBuilderDialogIsBusy(value));
  }

  function clearWidgetSelectStore() {
    dispatch(setCurrentWidget(""));
  }

  function closeEditItemPanel() {
    dispatch(
      editNodeAction({
        openDialog: "none",
        addNew: null,
        parentId: null,
      })
    );

    clearWidgetSelectStore();
  }

  function setBlockLabelFunc(e) {
    let val = e.target.value;
    if (val && val.length > 0) {
      const res = /[`*+%$[\]{}\\|<>~]/.test(val);
      if (!res || val === "") {
        setBlockLabel(val);
        if (block && block.label && block.label.length > 0) {
          setBlockLabelError(false);
        }
      }
    } else {
      setBlockLabel("");
    }
  }

  function addNewItem() {
    if (
      block.id &&
      block.type === "block" &&
      block.label &&
      block.label.length > 0 &&
      props.edit
    ) {
      dispatchBuilderDialogIsBusyAction(true);
      dispatch(
        addNewItemAction({
          item: block,
          config: props.edit,
          clickOnPlus: props.clickOnPlus,
          isSubWidget: props.isSubWidget,
          subType: props.subType,
          subParentType: props.subParentType,
          isAfterComplexWidget: props.isAfterComplexWidget,
          complexWidgetType: props.complexWidgetType,
          complexWidgetNext: props.complexWidgetNext,
          parentNext: props.parentNext,
          parentBlockNext: props.parentBlockNext,
          parentBlock: props.parentBlock,
        })
      );

      var app = props.builder.app;
      saveApptoServer(app);
    } else {
      if (!block.label || block.label.length === 0) {
        setBlockLabelError(true);
      }
    }
  }

  function saveApptoServer(app) {
    if (app && app.name && getAuthToken().token) {
      const rawData = {
        token: getAuthToken().token,
        appUsername: app.name,
        app: JSON.stringify(app),
      };
      axios({
        method: "post",
        url: config.url.REPLY_SERVER + "/api/v1/apps/saveapp",
        data: rawData,
        timeout: 15000,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
        .then(function (res) {
          if (res.data && res.data.response.status === 1) {
            dispatchBuilderDialogIsBusyAction(false);
            closeEditItemPanel();
          } else {
            if (res.data.response.errors) {
              dispatchBuilderDialogIsBusyAction(false);
              dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content: res.data.response.errors.errorContent,
                  severity: "error",
                  autoHide: true,
                })
              );
            }
          }
        })
        .catch(function (res) {
          dispatchBuilderDialogIsBusyAction(false);
          dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Action not saved",
              severity: "error",
              autoHide: true,
            })
          );
        });
    } else {
      dispatchBuilderDialogIsBusyAction(false);
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Bad request",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Stack spacing={3}>
      <Box>
        <TextField
          id='outlined-basic'
          error={blockLabelError}
          label='Label'
          variant='outlined'
          onChange={setBlockLabelFunc}
          fullWidth
          value={blockLabel}
          disabled={props.builder.builderDialogIsBusy ? true : false}
          inputProps={{ maxLength: 30 }}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Button
          variant='contained'
          fullWidth
          onClick={() => addNewItem()}
          disabled={
            props.builder.builderDialogIsBusy ? true : blockLabel ? false : true
          }>
          Save
        </Button>
      </Box>
    </Stack>
  );
}
export default connect(MapStateToProps)(SelectBlock);
