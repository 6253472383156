import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { connect, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Stack from "@mui/material/Stack";

const MapStateToProps = (state) => {
  return { builder: state.builderReducer };
};

function End(props) {
  const dispatch = useDispatch();
  var initEnd = uuidv4();
  useEffect(() => {}, [dispatch, initEnd]);
  return (
    <Stack direction='column' alignItems='center' justify='center'>
      <Button variant='outlined' disableRipple disableElevation>
        &nbsp;&nbsp;End&nbsp;&nbsp;
      </Button>
    </Stack>
  );
}
export default connect(MapStateToProps)(End);
